import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/user_car_listref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      listNum: 2,
      listData: [
        {
          carId: 1,
          carName: 'リーフ',
          carNumberRegion: '品川',
          carNumberClass: '500',
          carNumberHiragana: 'ね',
          carNumberSerial: '1234',
          carType: 100,
        },
        {
          carId: 2,
          carName: 'リーフ',
          carNumberRegion: '大阪',
          carNumberClass: '900',
          carNumberHiragana: 'な',
          carNumberSerial: '5555',
          carType: 0,
        },
      ],
    },
  });
};
