import _ from 'lodash';
import * as yup from 'yup';
import { Message } from 'yup/lib/types';

import {
  DECIMAL,
  DIGITS_LESS_THAN,
  formatMessage,
  HANKAKU_NUM,
  INPUT_ERROR,
  INTEGER,
  INTERVAL_ERROR,
  LESS_THAN,
  MATCHES,
  MORE_THAN,
  ZENKAKU,
  ZENKAKU_MAX,
  ZENKAKU_MIN,
  ZENKAKU_NOT_SPACE,
} from './messages';

export const customValidator = () => {};

////////////////////////////////////////////////////////////////////////////////
// 【使い方】
// 項目ごとにチェック関数を用意しているので、
//   import * as EveriwaVaridator from '../../common/EveriwaValidator'
// 等として本ファイルをインポート後に
// チェック対象の項目に該当する関数を呼んでください。
// 例) パスコードの場合
//   passcode: EveriwaVaridator.checkPasscode()
////////////////////////////////////////////////////////////////////////////////

declare module 'yup' {
  interface StringSchema {
    zenkakuInput(this: StringSchema, options: ZenkakuOptions, message?: string): this;
    include(this: StringSchema, options: IncludeOptions): this;
    numberInput(this: StringSchema, options: NumberOptions): this;
    zenkaku(this: StringSchema, message: Message): this;
    katakana(this: StringSchema, message: Message): this;
    notWhiteSpace(this: StringSchema, message: Message): this;
    notEmoji(this: StringSchema, message: Message): this;
    maxWith(this: StringSchema, relationKey: string, maxLength: number, message: Message): this;
    maxNumber(this: StringSchema, max: number, message: Message): this;
    minNumber(this: StringSchema, min: number, message: Message): this;
    timeRange(this: StringSchema, options: TimeRangeOptions): this;
  }
}

const rZenkaku = '([^\x00-\x7F\uFF61-\uFF9F])';
const rHiragana = '([\u3041-\u3096])';
const rKatakana = '([\u30A1-\u30FC])';
const rKanji = '([々〇〻\u3400-\u9FFF\uF900-\uFAFF]|[\uD840-\uD87F][\uDC00-\uDFFF])';
const rEmoji =
  '([\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}])';
// ひらがな、カタカナ、漢字の正規表現は以下のURLから引用
// (カタカナは長音を含める為、u+30FCまで拡張)
// https://so-zou.jp/software/tech/programming/tech/regular-expression/meta-character/variable-width-encoding.htm
// 絵文字の正規表現は以下のURLから引用
// https://www.web-dev-qa-db-ja.com/ja/javascript/javascript-unicode-emoji正規表現/830969572/

// 共通エラーメッセージ
// yup.setLocaleは全体に影響が出るのでここでは使用を控えておく
const requiredMessage = '必ず入力してください';

type ZenkakuOptions = {
  trim?: boolean;
  required?: boolean;
  notEmoji?: boolean;
  notWhiteSpace?: boolean;
  min?: number;
  max?: number;
};

type IncludeOptions = {
  required?: boolean;
  code: { [key in string | number]: string };
};

type NumberOptions = {
  required?: boolean;
  min: number;
  max: number;
  integerScale: number;
  decimalScale: number;
  rangeMessage?: string;
};

type TimeRangeOptions = {
  required?: boolean;
  mode: 'start' | 'end';
  interval: number;
};

yup.addMethod(
  yup.string,
  'timeRange',
  function ({ required = false, mode, interval }: TimeRangeOptions) {
    let validator: yup.StringSchema = _.cloneDeep(this);
    validator = validator
      .test('timeRangeBase', INPUT_ERROR, function (time: string | undefined) {
        const startTime = mode === 'start' ? time : this.parent.startTime;
        const endTime = mode === 'end' ? time : this.parent.endTime;
        if (!startTime && !endTime) return !required;
        if (!startTime && endTime) return false;
        if (startTime && !endTime) return false;
        if (startTime && endTime) {
          if (Number(startTime.replace(':', '')) > Number(endTime.replace(':', ''))) {
            return false;
          }
        }
        return true;
      })
      .test(
        'timeRangeInterval',
        formatMessage(INTERVAL_ERROR, `${interval / 100}時間`),
        function (time: string | undefined) {
          const startTime = mode === 'start' ? time : this.parent.startTime;
          const endTime = mode === 'end' ? time : this.parent.endTime;
          if (startTime && endTime) {
            if (Number(startTime.replace(':', '')) + interval > Number(endTime.replace(':', ''))) {
              return false;
            }
          }
          return true;
        }
      );

    return validator;
  }
);

yup.addMethod(
  yup.string,
  'zenkakuInput',
  function (
    {
      trim = false,
      required = false,
      notEmoji = false,
      notWhiteSpace = false,
      min,
      max,
    }: ZenkakuOptions,
    message: string
  ) {
    let validator: yup.StringSchema = _.cloneDeep(this);
    if (trim) validator = validator.trim();
    if (required) validator = validator.required();
    if (min) validator = validator.min(min, message ?? formatMessage(ZENKAKU_MIN, min));
    if (max) validator = validator.max(max, message ?? formatMessage(ZENKAKU_MAX, max));
    validator = validator.matches(new RegExp(`^${rZenkaku}*$`), message ?? ZENKAKU);
    if (notEmoji) validator = validator.notEmoji(message ?? MATCHES);
    if (notWhiteSpace) validator = validator.notWhiteSpace(message ?? ZENKAKU_NOT_SPACE);
    return validator;
  }
);

yup.addMethod(yup.string, 'include', function ({ required = false, code }: IncludeOptions) {
  let validator: yup.StringSchema = _.cloneDeep(this);
  if (required) validator = validator.required();
  validator.oneOf(Object.keys(code).map(String));
  return validator;
});

yup.addMethod(
  yup.string,
  'numberInput',
  function ({
    required = false,
    min,
    max,
    integerScale,
    decimalScale,
    rangeMessage,
  }: NumberOptions) {
    let validator: yup.StringSchema = _.cloneDeep(this);
    validator = validator.trim();
    if (required) validator = validator.required();
    validator = validator.test('', HANKAKU_NUM, function (value) {
      return value ? new RegExp(/^[-]?([1-9]\d*|0)(\.\d+)?$/).test(value) : true;
    });
    validator = validator.minNumber(
      min - 10 ** (-1 * decimalScale),
      rangeMessage ?? formatMessage(MORE_THAN, min - 10 ** (-1 * decimalScale))
    );
    validator = validator.maxNumber(
      max + 10 ** (-1 * decimalScale),
      rangeMessage ?? formatMessage(LESS_THAN, max + 10 ** (-1 * decimalScale))
    );
    validator = validator.test('', DECIMAL, function (value) {
      return value && integerScale === 0 ? new RegExp(/^-?[0-9]+\.[0-9]+$/).test(value) : true;
    });
    validator = validator.test('', INTEGER, function (value) {
      return value && decimalScale === 0 ? new RegExp(/^-?[0-9]+$/).test(value) : true;
    });
    validator = validator.test('', formatMessage(DIGITS_LESS_THAN, decimalScale), function (value) {
      if (value) {
        let regex = '^0';
        if (decimalScale > 0) {
          regex += `(\\.[0-9]{1,${decimalScale}})?`;
        }
        regex += `$|^[1-9]`;
        if (integerScale > 1) {
          regex += `[0-9]{0,${integerScale - 1}}`;
        }
        if (decimalScale > 0) {
          regex += `(\\.[0-9]{1,${decimalScale}})?`;
        }
        regex += '$';
        return new RegExp(regex).test(value);
      }
      return true;
    });
    return validator;
  }
);

////////////////////////////////////////////////////////////////////////////////
// 全角文字チェック関数
// ASCII文字と半角カナ以外を全角文字とする。
////////////////////////////////////////////////////////////////////////////////
yup.addMethod(yup.string, 'zenkaku', function (message: Message) {
  return this.matches(new RegExp(`^${rZenkaku}*$`), message);
});

////////////////////////////////////////////////////////////////////////////////
// カタカナチェック関数
////////////////////////////////////////////////////////////////////////////////
yup.addMethod(yup.string, 'katakana', function (message: Message) {
  return this.matches(new RegExp(`^${rKatakana}*$`), message);
});

////////////////////////////////////////////////////////////////////////////////
// 空白文字チェック関数
// 一文字でも空白文字(全角スペース、半角スペース、\t、\n、\r、\f)が
// 含まれていた場合falseを返す。
////////////////////////////////////////////////////////////////////////////////
yup.addMethod(yup.string, 'notWhiteSpace', function (message: Message) {
  return this.test('notWhiteSpace', message, function (value) {
    if (!value) {
      // 空文字列はtrueを返す(必須チェックはrequiredでおこなう)
      return true;
    } else {
      // eslint-disable-next-line no-useless-escape
      return !new RegExp(`[\s\u3000]`, 'ug').test(value);
    }
  });
});

////////////////////////////////////////////////////////////////////////////////
// 絵文字チェック関数
// 一文字でも絵文字が含まれていた場合falseを返す。
////////////////////////////////////////////////////////////////////////////////
yup.addMethod(yup.string, 'notEmoji', function (message: Message) {
  return this.test('notEmoji', message, function (value) {
    if (!value) {
      // 空文字列はtrueを返す(必須チェックはrequiredでおこなう)
      return true;
    } else {
      return !new RegExp(`${rEmoji}`, 'ug').test(value);
    }
  });
});

////////////////////////////////////////////////////////////////////////////////
// 複数項目の合計による最大長チェック
// 引数
//   relationKey: 関連する項目のプロパティ名
//   maxLength  : 最大長
//   message    : エラーメッセージ
////////////////////////////////////////////////////////////////////////////////
yup.addMethod(
  yup.string,
  'maxWith',
  function (relationKey: string, maxLength: number, message: Message) {
    return this.when(relationKey, function (value, schema) {
      if (value.length >= maxLength) {
        // 関連項目のみで最大長超えているのでfalseで返す。
        return schema.test('returnInvalid', message, () => {
          return false;
        });
      } else {
        return schema.max(maxLength - value.length, message);
      }
    });
  }
);

////////////////////////////////////////////////////////////////////////////////
// 数値としての最小値のチェック
// 引数
//   min:     許容する数値としての最小値
//   message: エラーメッセージ
////////////////////////////////////////////////////////////////////////////////
yup.addMethod(yup.string, 'minNumber', function (min: number, message: Message) {
  return this.test('minNumber', message, function (value) {
    if (!value) {
      // 空文字列はtrueを返す(必須チェックはrequiredでおこなう)
      return true;
    } else {
      const num = Number.parseFloat(value);
      if (Number.isNaN(num)) {
        // 数値以外の場合はtrueを返す(フォーマットチェックは別途おこなう)
        return true;
      } else {
        return num > min;
      }
    }
  });
});

////////////////////////////////////////////////////////////////////////////////
// 数値としての最大値のチェック
// 引数
//   max:     許容する数値としての最大値
//   message: エラーメッセージ
////////////////////////////////////////////////////////////////////////////////
yup.addMethod(yup.string, 'maxNumber', function (max: number, message: Message) {
  return this.test('maxNumber', message, function (value) {
    if (!value) {
      // 空文字列はtrueを返す(必須チェックはrequiredでおこなう)
      return true;
    } else {
      const num = Number.parseFloat(value);
      if (Number.isNaN(num)) {
        // 数値以外の場合はtrueを返す(フォーマットチェックは別途おこなう)
        return true;
      } else {
        return num < max;
      }
    }
  });
});

////////////////////////////////////////////////////////////////////////////////
// メールアドレス
// 入力桁制約   : 255文字以内
// 入力内容制約 : 半角英数字のみ(メールアドレスフォーマットに従う？)
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkEmailAddress() {
  const maxLength = 255;
  const messageFormat = `半角英数字のみで入力してください`;
  const messageRange = `${maxLength}文字以内で入力してください`;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .required(messageRequired)
    .max(maxLength, messageRange)
    .matches(new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/), messageFormat)
    .email(messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// 電話番号(携帯電話)
// 入力桁制約   : 10桁以上11桁以下
// 入力内容制約 : 半角数字のみ
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkHandyPhoneNumber() {
  const messageFormat = `10桁または11桁の半角数字で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .required(messageRequired)
    .min(10, messageRange)
    .max(11, messageRange)
    .matches(/^[0-9]*$/, messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// 電話番号(固定電話想定)
// 入力桁制約   : 9桁以上11桁以下
// 入力内容制約 : 半角数字のみ
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkPhoneNumber() {
  const messageFormat = `9桁から11桁の半角数字で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .required(messageRequired)
    .min(9, messageRange)
    .max(11, messageRange)
    .matches(/^[0-9]*$/, messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// パスコード 1～6
// ※連結した文字列で管理しているので、それに沿ってチェックする。
// 入力桁制約   : 6桁
// 入力内容制約 : 半角数字のみ
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkPasscode() {
  const length = 6;
  const messageFormat = `半角数字${length}桁で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .required(messageRequired)
    .length(length, messageRange)
    .matches(/^[0-9]*$/, messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// ニックネーム
// 入力桁制約   : 12文字以内
// 入力内容制約 : なし
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkNickName() {
  const maxLength = 12;
  const messageRange = `${maxLength}文字以内で入力してください`;
  const messageRequired = requiredMessage;

  return yup.string().trim().required(messageRequired).max(maxLength, messageRange);
}

////////////////////////////////////////////////////////////////////////////////
// 氏
// 入力桁制約   : 64文字以内 ※名と合わせて30文字以内
// 入力内容制約 : 全角のみ(絵文字不可、スペース不可)
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkLastName(firstNameKey = 'name') {
  const maxLength = 30;
  const messageFormat = `氏名合わせて${maxLength}文字以内の全角で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .required(messageRequired)
    .maxWith(firstNameKey, maxLength, messageRange)
    .zenkaku(messageFormat)
    .notEmoji(messageFormat)
    .notWhiteSpace(messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// 名
// 入力桁制約   : 64文字以内 ※氏と合わせて30文字以内
// 入力内容制約 : 全角のみ(絵文字不可、スペース不可)
// 必須
////////////////////////////////////////////////////////////////////////////////
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function checkFirstName(lastNameKey = 'surname') {
  const maxLength = 30;
  const messageFormat = `氏名合わせて${maxLength}文字以内の全角で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return (
    yup
      .string()
      .trim()
      .required(messageRequired)
      // 循環参照でエラーになるので単純なレングスチェックにする
      // .maxWith(lastNameKey, maxLength, messageRange)
      .max(64, messageRange)
      .zenkaku(messageFormat)
      .notEmoji(messageFormat)
      .notWhiteSpace(messageFormat)
  );
}

////////////////////////////////////////////////////////////////////////////////
// 氏（カタカナ）
// 入力桁制約   : 128文字以内 ※名（カタカナ）と合わせて25文字以内
// 入力内容制約 : 全角カタカナのみ
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkLastNameKana(firstNameKanaKey = 'nameRuby') {
  const maxLength = 25;
  const messageFormat = `氏名合わせて${maxLength}文字以内の全角カナで入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .required(messageRequired)
    .maxWith(firstNameKanaKey, maxLength, messageRange)
    .katakana(messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// 名（カタカナ）
// 入力桁制約   : 128文字以内 ※氏（カタカナ）と合わせて25文字以内
// 入力内容制約 : 全角カタカナのみ
// 必須
////////////////////////////////////////////////////////////////////////////////
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function checkFirstNameKana(lastNameKanaKey = 'surnameRuby') {
  const maxLength = 25;
  const messageFormat = `氏名合わせて${maxLength}文字以内の全角カナで入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return (
    yup
      .string()
      .trim()
      .required(messageRequired)
      // 循環参照でエラーになるので単純なレングスチェックにする
      // .maxWith(lastNameKanaKey, maxLength, messageRange)
      .max(128, messageRange)
      .katakana(messageFormat)
  );
}

////////////////////////////////////////////////////////////////////////////////
// 郵便番号
// 入力桁制約   : 7桁
// 入力内容制約 : 半角数字のみ
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkPostCode() {
  const length = 7;
  const messageFormat = `半角数字${length}桁で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .required(messageRequired)
    .length(length, messageRange)
    .matches(/^[0-9]*$/, messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// 法人名
// 入力桁制約   : 128文字以内
// 入力内容制約 : 全角のみ(絵文字不可、スペース不可)
// ユーザー種別が「法人」の場合は必須
////////////////////////////////////////////////////////////////////////////////
export function checkCorporateName(corporateFlagKey = 'userKind') {
  const maxLength = 128;
  const messageFormat = `空白を含まない全角で入力してください`;
  const messageRange = `${maxLength}文字以内で入力してください`;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .when(corporateFlagKey, (value, schema) => {
      return value ? schema.required(messageRequired) : schema;
    })
    .max(maxLength, messageRange)
    .zenkaku(messageFormat)
    .notEmoji(messageFormat)
    .notWhiteSpace(messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// 法人名（カタカナ）
// 入力桁制約   : 128文字以内
// 入力内容制約 : 全角カタカナのみ
// ユーザー種別が「法人」の場合は必須
////////////////////////////////////////////////////////////////////////////////
export function checkCorporateNameKana(corporateFlagKey = 'userKind') {
  const maxLength = 128;
  const messageFormat = `全角カタカナで入力してください`;
  const messageRange = `${maxLength}文字以内で入力してください`;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .when(corporateFlagKey, (value, schema) => {
      return value ? schema.required(messageRequired) : schema;
    })
    .max(maxLength, messageRange)
    .katakana(messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// 車名
// 入力桁制約   : 255文字以内
// 入力内容制約 : なし
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkCarName() {
  const maxLength = 255;
  const messageRange = `${maxLength}文字以内で入力してください`;
  const messageRequired = requiredMessage;

  return yup.string().trim().required(messageRequired).max(maxLength, messageRange);
}

////////////////////////////////////////////////////////////////////////////////
// 車両番号（地域名）
// 入力桁制約   : 255文字以内
// 入力内容制約 : 全角ひらがな、全角カタカナ、漢字のみ
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkCarNumberRegion() {
  const maxLength = 255;
  const messageFormat = `${maxLength}文字以内の全角で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;
  const regex = new RegExp(`^(${rHiragana}|${rKatakana}|${rKanji})*$`);

  return yup
    .string()
    .required(messageRequired)
    .max(maxLength, messageRange)
    .matches(regex, messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// 車両番号（分類番号）
// 入力桁制約   : 3桁
// 入力内容制約 : 半角英数字のみ
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkCarNumberClass() {
  const length = 3;
  const messageFormat = `半角英数字${length}桁で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .required(messageRequired)
    .length(length, messageRange)
    .matches(/^[0-9A-Z]*$/, messageFormat);
}

////////////////////////////////////////////////////////////////////////////////
// 車両番号（平仮名等）
// 入力桁制約   : 1桁
// 入力内容制約 : 平仮名1文字 or 大文字英字1文字
//                許容文字は https://www.airia.or.jp/info/number/01.html から抽出
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkCarNumberHiragana() {
  const messageFormat = `ひらがなまたは全角英大文字で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .required(messageRequired)
    .length(1, messageRange)
    .matches(
      /^[あいうえかきくけこさすせそたちつてとなにぬねのはひふほまみむめもやゆよらりるれろわをＡＢＥＨＫＭＴＹ]*$/,
      messageFormat
    );
}

////////////////////////////////////////////////////////////////////////////////
// 車両番号（一連指定番号）
// 入力桁制約   : 4桁
// 入力内容制約 : 半角数字4桁。・は0として入力してもらう想定(数値として1～9999の範囲)
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkCarNumberSerial() {
  const messageFormat = `4桁の半角数字で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .required(messageRequired)
    .length(4, messageRange)
    .matches(
      /(^0{3}[1-9]$)|(^0{2}[1-9][0-9]$)|(^0[1-9][0-9]{2}$)|(^[1-9][0-9]{3}$)/,
      messageFormat
    );
}

////////////////////////////////////////////////////////////////////////////////
// QRコード番号
// 入力桁制約   : 12桁
// 入力内容制約 : 半角数字のみ
// 必須
////////////////////////////////////////////////////////////////////////////////
export function checkQrCodeNumber() {
  const length = 12;
  const messageFormat = `半角数字${length}桁で入力してください`;
  const messageRange = messageFormat;
  const messageRequired = requiredMessage;

  return yup
    .string()
    .trim()
    .required(messageRequired)
    .length(length, messageRange)
    .matches(/^[0-9]*$/, messageFormat);
}
