import clsx from 'clsx';

import { Line } from '../Line/Line';

type TitleProps = {
  children: React.ReactNode;
  className?: string;
  mx?: string;
  isHr?: boolean;
};
export const Title = ({ children, className, mx = 'mx-6', isHr = true }: TitleProps) => {
  return (
    <>
      <h1
        className={clsx(
          'font-medium text-3xl leading-8 p-0 mt-[1.1875rem] mr-[auto] mb-[1.125rem] ml-6 text-main-700',
          className
        )}
      >
        {children}
      </h1>
      {isHr && <Line mx={mx} />}
    </>
  );
};
