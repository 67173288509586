import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/evc_listref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      listNum: 2,
      listData: [
        {
          evcEquipmentId: '123456789',
          hostPlaceName: 'PayPayドーム',
          evcNickName: '設備１',
          evcPhotoUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PLACE/place01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=9996b6225fedaacadbb36cba1dced76fde3a11cb3f449832f0381d79dc3bbcd4',
          reservationFlag: 2,
          existCoupon: true,
          maxFeeInfomation: 2000,
          minFeeInfomation: 1000,
          maxAvailableCarType: 200,
          voltage: 1,
          outputVoltage: '3.2',
        },
        {
          evcEquipmentId: '234567890',
          hostPlaceName: '博多駅',
          evcNickName: '設備２',
          evcPhotoUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=4b5be255b250efd867fb1a8dd8b0d1d5d8bd6348dc801f91a00b8216373aeb40',
          reservationFlag: 1,
          existCoupon: true,
          maxFeeInfomation: 3000,
          minFeeInfomation: 2500,
          maxAvailableCarType: 300,
          voltage: 2,
          outputVoltage: '2.1',
        },
        {
          evcEquipmentId: '345678901',
          hostPlaceName: '新大阪駅',
          evcNickName: '設備３',
          evcPhotoUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=4b5be255b250efd867fb1a8dd8b0d1d5d8bd6348dc801f91a00b8216373aeb40',
          reservationFlag: null,
          existCoupon: true,
          maxFeeInfomation: null,
          minFeeInfomation: null,
          maxAvailableCarType: 300,
          voltage: 2,
          outputVoltage: '2.1',
        },
      ],
    },
  });
};
