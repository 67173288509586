import * as React from 'react';

type Option = {
  label: React.ReactNode;
  value: string;
};

type SelectFieldProps = {
  options: Option[];
  onChange: (v: string) => void;
  selected?: string | number;
};

export const SelectFilter = (props: SelectFieldProps) => {
  const { options, onChange, selected } = props;
  return (
    <div className="select-item">
      <select
        className="form-select"
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => onChange(e.target.value)}
      >
        {options.map(({ label, value }) => (
          <option
            className="text-black"
            key={label?.toString()}
            value={value}
            defaultValue={selected}
          >
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};
