export const Footer = () => {
  return (
    <>
      <div id="footer" className="bg-main-300 w-full flex justify-between">
        <div className="flex h-footer leading-footer ml-8">
          <div>
            <a href={externalLink.POLICY} target="_blank" rel="noreferrer">
              利用規約・プライバシーポリシー
            </a>
          </div>
          <div className="mx-4">|</div>
          <div>
            <a href={externalLink.SWITCH} target="_blank" rel="noreferrer">
              everiwaスイッチ
            </a>
          </div>
          <div className="mx-4">|</div>
          <div>
            <a href={externalLink.SCT} target="_blank" rel="noreferrer">
              特定商取引法に基づく表記
            </a>
          </div>
        </div>
        <div className="h-footer leading-footer text-right mr-8">
          &copy; Panasonic Corporation 2022-2023
        </div>
      </div>
    </>
  );
};

const externalLink = {
  POLICY: 'https://help.charger-share.everiwa.jp/about/policy/', // 利用規約・プライバシーポリシー
  SWITCH: 'https://manual.charger-share.everiwa.jp/WZ7976/app/index.html', // everiwaスイッチ
  SCT: 'https://help.charger-share.everiwa.jp/about/trade/', // 特定商取引(Specified commercial transaction)
};
