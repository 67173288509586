import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  const uri = 'test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com';
  const reg = new RegExp(`${uri}/*`);

  mock.onPut(reg).reply(200, {
    result: 0,
    err: [],
    data: {},
  });
};
