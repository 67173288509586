import { useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { prefectures } from '@/business_code/Prefectures';
import { Button } from '@/components/Elements';
import { ButtonGroup } from '@/components/Elements/Button/ButtonGroup';
import { Title } from '@/components/Elements/Title/Title';
import { InputField, SelectField, ToggleCheckboxField } from '@/components/Form';
import { AddressSearchButton } from '@/components/Form/AddressButton';
import { Container, Row, Col } from '@/components/Layout/Grid';

type InputProps = {
  methods: UseFormReturn<any>;
};

export const UserAddInput = ({ methods }: InputProps) => {
  const { register, formState, clearErrors, setError, setValue, watch, control } = methods;

  const watchUserKind = watch('userKind');

  useEffect(() => {
    if (watchUserKind !== '1') {
      setValue('corporateName', '');
      setValue('corporateNameRuby', '');
    }
  }, [setValue, watchUserKind]);

  const prefecturesRef = useRef<HTMLSelectElement>(null);

  return (
    <>
      <Title className="text-center font-bold ml-0 mr-0" mx="mx-0">
        会員登録
      </Title>
      <img src="/assets/img/icon_user_line_circle_80.png" className="my-8 w-max mx-auto" alt="" />
      <img src="/assets/img/flow_03.png" className="w-max mx-auto" alt="" />
      <Container px="px-64">
        <Row mb="mb-4">
          <Col col={4}>
            <InputField
              label="ニックネーム"
              registration={register('nickName', {})}
              error={formState.errors['nickName']}
              required
              placeholder="12文字以内"
            />
          </Col>
        </Row>
        <Row mb="mb-4">
          <Col col={2}>
            <InputField
              label="氏名"
              registration={register('surname', {})}
              error={formState.errors['surname']}
              required
              placeholder="松下"
            />
          </Col>
          <Col col={2}>
            <InputField
              label=""
              registration={register('name', {})}
              error={formState.errors['name']}
              required
              placeholder="太郎"
              className="mt-11"
            />
          </Col>
        </Row>
        <Row mb="mb-4">
          <Col col={2}>
            <InputField
              label="氏名（カタカナ）"
              registration={register('surnameRuby', {})}
              error={formState.errors['surnameRuby']}
              required
              placeholder="マツシタ"
            />
          </Col>
          <Col col={2}>
            <InputField
              label=""
              registration={register('nameRuby', {})}
              error={formState.errors['nameRuby']}
              required
              placeholder="タロウ"
              className="mt-11"
            />
          </Col>
        </Row>
        <Row col={8} mb="mb-4">
          <Col col={3}>
            <InputField
              label="郵便番号"
              registration={register('postCode', {})}
              error={formState.errors['postCode']}
              required
              placeholder="郵便番号を入力"
            />
          </Col>
          <Col col={3}>
            <AddressSearchButton
              postCode={watch('postCode')}
              setErrorFieldName={'postCode'}
              setValuePrefectures={'prefectures'}
              setValueMunicipalities={'municipalities'}
              clearErrors={clearErrors}
              setValue={setValue}
              setError={setError}
              prefecturesRef={prefecturesRef}
            />
          </Col>
        </Row>
        <Row mb="mb-4">
          <Col col={4}>
            <SelectField
              label="都道府県"
              options={prefectures}
              registration={register('prefectures', {})}
              error={formState.errors['prefectures']}
              required
              placeholder="都道府県を選択"
              innerRef={prefecturesRef}
            />
          </Col>
        </Row>
        <Row mb="mb-4">
          <Col col={4}>
            <InputField
              label="市区町村"
              registration={register('municipalities', {})}
              error={formState.errors['municipalities']}
              required
              placeholder="〇〇市〇〇区〇〇町"
            />
          </Col>
        </Row>
        <Row mb="mb-4">
          <Col col={4}>
            <InputField
              label="丁目・番地"
              registration={register('address', {})}
              error={formState.errors['address']}
              required
              placeholder="1-1"
            />
          </Col>
        </Row>
        <Row mb="mb-4">
          <Col col={4}>
            <InputField
              label="ビル、マンション名"
              registration={register('buildingName', {})}
              error={formState.errors['buildingName']}
              placeholder="○○マンション101号室"
            />
          </Col>
        </Row>
        <Row col={6} mb="mb-4">
          <Col col={2}>
            <SelectField
              label="生年月日"
              registration={register('birthYear', {})}
              error={formState.errors['birthYear']}
              options={(() => {
                const years = [];
                for (let i = 1900; i <= new Date().getFullYear(); i++) {
                  years.push({ label: String(i), value: String(i) });
                }
                return years;
              })()}
              placeholder="年"
              required
            />
          </Col>
          <Col col={2}>
            <SelectField
              label=""
              registration={register('birthMonth', {})}
              error={formState.errors['birthMonth']}
              options={(() => {
                const months = [];
                for (let i = 1; i <= 12; i++) {
                  months.push({ label: String(i), value: String(i) });
                }
                return months;
              })()}
              placeholder="月"
              className="mt-11"
            />
          </Col>
          <Col col={2}>
            <SelectField
              label=""
              registration={register('birthDate', {})}
              error={formState.errors['birthDate']}
              options={(() => {
                const days = [];
                // 可変にする場合、以下を実装。現状は31日まで表示し、validationで弾く。
                // const lastday = new Date(Number(year), Number(month), 0).getDate()
                for (let i = 1; i <= 31; i++) {
                  days.push({ label: String(i), value: String(i) });
                }
                return days;
              })()}
              placeholder="日"
              className="mt-11"
            />
          </Col>
        </Row>
        <Row mb="mb-4">
          <Col col={4}>
            <InputField
              label="登録番号"
              registration={register('hostRegistrationNumber', {})}
              error={formState.errors['hostRegistrationNumber']}
              placeholder="T+数字13桁"
              supplement="適格請求書発行事業者の登録番号"
            />
          </Col>
        </Row>
        <Row mb="mb-4" mt="mt-10">
          <Col col={4}>
            <ToggleCheckboxField
              name="userKind"
              control={control}
              itemLabel="法人の方はこちらにチェックを入れてください。"
            />
          </Col>
        </Row>
        {watchUserKind === '1' && (
          <>
            <Row mb="mb-2">
              <Col col={4}>
                法人ホストになる方は登録するみずほ銀行口座名義と同じ法人名を記載してください。
              </Col>
            </Row>
            <Row mb="mb-4">
              <Col col={4}>
                <InputField
                  label="法人名"
                  registration={register('corporateName', {})}
                  error={formState.errors['corporateName']}
                  placeholder="パナソニック株式会社"
                />
              </Col>
            </Row>
            <Row mb="mb-4">
              <Col col={4}>
                <InputField
                  label="法人名（カタカナ）"
                  registration={register('corporateNameRuby', {})}
                  error={formState.errors['corporateNameRuby']}
                  placeholder="パナソニックカブシキガイシャ"
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
      <div className="mb-12">
        <ButtonGroup align="center">
          <Button type="submit">次へ</Button>
        </ButtonGroup>
      </div>
    </>
  );
};
