import clsx from 'clsx';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

type FieldWrapperProps = {
  label?: string;
  required?: boolean;
  className?: string;
  children: React.ReactNode;
  error?: FieldError | undefined;
  description?: string;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, required, error, children, description } = props;
  return (
    <div>
      <div>
        {label && (
          <label className={clsx('block mt-4')}>
            {label}
            {required && <span className="text-error">※</span>}
          </label>
        )}
        {description && <p className="mt-1">{description}</p>}
        <div className={label ? 'mt-1' : 'mt-0'}>{children}</div>
      </div>
      {error?.message && (
        <div role="alert" aria-label={error.message} className="font-semibold text-error">
          {error.message}
        </div>
      )}
    </div>
  );
};
