import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

type PaginationType = {
  itemsPerPage: number;
  items: any[];
  setCurrentItems: (l: any[]) => void;
  onPageChange?: () => void;
};

export const Pagination = (props: PaginationType) => {
  const { itemsPerPage, items, setCurrentItems, onPageChange } = props;
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const [forcePage, setForcePage] = useState<number>();

  useEffect(() => {
    setItemOffset(0);
  }, [items.length]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
    setForcePage(Math.ceil(itemOffset / itemsPerPage));
  }, [itemOffset, items, itemsPerPage, setCurrentItems]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    if (onPageChange) onPageChange();
    setItemOffset(newOffset);
  };

  return (
    <ReactPaginate
      forcePage={forcePage}
      onPageChange={handlePageClick}
      pageRangeDisplayed={4}
      pageCount={pageCount}
      renderOnZeroPageCount={() => null}
      containerClassName="pagenation flex gap-x-2" // ul(pagination本体)
      pageClassName="page-item" // li
      pageLinkClassName="page-link" // a
      // activeClassName="page-link current" // active.li
      activeLinkClassName="current" // active.li < a
      previousClassName="page-item" // li
      nextClassName="page-item" // li
      previousLinkClassName="page-link page-link__prev"
      nextLinkClassName="page-link page-link__next"
      breakLabel="…"
      breakClassName="page-item"
      breakLinkClassName="page-link omission"
    />
  );
};
