import { NavLink, useLocation } from 'react-router-dom';

import { PROTECTED_ROUTE_BASE } from '@/routes/protected';

import { RouteProps } from './Routes';

export type BreadcrumbsProps = {
  base?: string;
  prefix: string;
  routes: RouteProps[];
};

export const Breadcrumbs = ({ base = PROTECTED_ROUTE_BASE, prefix, routes }: BreadcrumbsProps) => {
  const location = useLocation();
  const paths = pathNodes(slicePath(location.pathname, base, prefix));

  const breadcrumbs: RouteProps[] = [];

  paths.forEach((p) => {
    routes.some((r) => {
      if (matchPath(p, r.path)) {
        breadcrumbs.push(r);
        return true;
      } else {
        return false;
      }
    });
  });

  console.log(breadcrumbs);

  const absolutePath = base + prefix;
  return (
    <div className="mt-6 ml-8 mb-4">
      <ul className="flex list-none">
        {breadcrumbs.map((item, index) => {
          return (
            <>
              <li className="text-txblack-alpha-900 text-[0.6875rem]">
                {index !== breadcrumbs.length - 1 ? (
                  <NavLink
                    to={item.path ? joinPaths([absolutePath, item.path]) : absolutePath}
                    className="text-txblack-alpha-600 no-underline"
                  >
                    {item.title}
                  </NavLink>
                ) : (
                  <span>{item.title}</span>
                )}
              </li>
              {index !== breadcrumbs.length - 1 && (
                <img src="/assets/img/icon_bread_crumb.png" alt="" className="mx-2 my-0" />
              )}
            </>
          );
        })}
      </ul>
    </div>
  );
};

const slicePath = (pathname: string, base: string, prefix: string) => {
  const absolutePath = base + prefix;
  if (!pathname.startsWith(absolutePath))
    throw new Error(
      'Breadcrumbs slicePath: The absolute path of the child route must start with the parent path.'
    );
  return pathname.substring(absolutePath.length);
};

const pathNodes = (pathname: string) =>
  pathname.split('/').reduce<string[]>((prev, current) => {
    return prev.concat(joinPaths([prev.slice(-1)[0], current]));
  }, []);

const joinPaths = (paths: string[]): string => paths.join('/').replace(/\/\/+/g, '/');

const matchPath = (path: string, route: string | undefined) => {
  if (path === route) {
    return true;
  } else {
    if (path === '/' && route === undefined) {
      return true;
    }
  }
  return false;
};
