import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { useLogout } from '@/hooks/useLogout';

export const UserInfoOnlyLogout = () => {
  const { logout } = useLogout();

  return (
    <div id="user_info">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex w-full justify-center  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          <div id="user_name">
            <img src="/assets/img/icon_Arrow_downSmall.png" alt="" />
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute shadow right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100' : 'bg-white'
                    } group flex w-full items-center rounded px-2 py-2`}
                    onClick={() => {
                      logout({
                        returnTo: window.location.origin + '/logout_callback/',
                      });
                    }}
                  >
                    ログアウト
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
