import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/rsv_user_listref_api').reply((config) => {
    console.log(JSON.stringify(config.data));
    return [
      200,
      {
        result: 0,
        err: [
          {
            errCode: 0,
            errMsg: '',
          },
        ],
        data: {
          listNum: 3,
          listData: [
            {
              reservationId: '3',
              reservationStartDatetime: '2022-02-10 12:00:00',
              reservationEndDatetime: '2022-02-10 13:00:00',
              evcAddress: 'yamada@yahoo.co.jp',
              reservationState: 1,
              chargingFee: 100,
            },
            {
              reservationId: '5',
              reservationStartDatetime: '2022-02-11 15:15:00',
              reservationEndDatetime: '2022-02-10 18:15:00',
              evcAddress: 'yamada@yahoo.co.jp',
              reservationState: 2,
              chargingFee: 1234,
            },
            {
              reservationId: '8',
              reservationStartDatetime: '2022-02-12 12:00:00',
              reservationEndDatetime: '2022-02-12 13:00:00',
              evcAddress: 'yamada@yahoo.co.jp',
              reservationState: 1,
              chargingFee: 10000,
            },
          ],
        },
      },
    ];
  });
};
