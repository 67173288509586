import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type UserMypageRefApiQuery = {
  nickName: string;
  dispUserId: string;
  coinBalance: number;
  userPhotoGetUrl: string;
  mailAddress: string;
  phoneNumber: string;
};

export const UserMypageRefApiErrType = {
  0: 'エラー無し',
  1: '該当設備無',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

type UserMypageRefApiErrTypeKey = keyof typeof UserMypageRefApiErrType;

export const useUserMypageRefApiQuery = <TData = UserMypageRefApiQuery>(
  variables: undefined,
  options?: UseQueryOptions<UserMypageRefApiQuery, ApiError<UserMypageRefApiErrTypeKey>, TData>
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useQuery<UserMypageRefApiQuery, ApiError<UserMypageRefApiErrTypeKey>, TData>(
    ['user_mypage_ref_api', variables],
    useFetchData<UserMypageRefApiQuery, ApiError<UserMypageRefApiErrTypeKey>, undefined>(
      'user_mypage_ref_api'
    ).bind(null, variables),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert('アクセスに失敗しました。再度ログインしてください。');
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
