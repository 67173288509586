/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, ClipboardEvent, KeyboardEvent, useRef } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { Flex, Item } from '../Layout/Flex';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type OtpFieldsProps = FieldWrapperPassThroughProps & {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  name: string;
};

export const OtpFields = ({ error, register, setValue, name }: OtpFieldsProps) => {
  const focusRegister = useFocusControl();
  return (
    <>
      <FieldWrapper error={error}>
        <Flex className="gap-4">
          {[...Array(6)].map((_, i) => {
            const registration = register(`${name}${i + 1}`, {});
            const focustRegistration = focusRegister(i);
            return (
              <Item className="w-16" key={`${name}${i + 1}`}>
                <input
                  type="tel"
                  name={registration.name}
                  className="form-input w-full text-center"
                  ref={(e) => {
                    registration.ref(e);
                    focustRegistration.ref(e);
                    if (i === 0) e?.focus();
                  }}
                  onChange={(e) => {
                    registration.onChange(e);
                    focustRegistration.onChange(e);
                  }}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={i === 0}
                  onBlur={registration.onBlur}
                  onPaste={(e) => focustRegistration.onPaste(e, setValue)}
                  onKeyDown={(e) => focustRegistration.onkeydown(e)}
                  placeholder="-"
                  step={1}
                  min={0}
                  max={9}
                  maxLength={1}
                />
              </Item>
            );
          })}
        </Flex>
      </FieldWrapper>
    </>
  );
};

const useFocusControl = () => {
  const ref = useRef(new Map<number, HTMLInputElement>());
  return (index: number) => ({
    onChange(e: ChangeEvent<HTMLInputElement>) {
      if (e.currentTarget.value && e.currentTarget.value.length === 1) {
        const sortedIndices = [...ref.current.keys()].sort();
        const nextIndex = sortedIndices[sortedIndices.indexOf(index) + 1];
        if (typeof nextIndex === 'number') ref.current.get(nextIndex)?.focus();
      }
    },
    onkeydown(e: KeyboardEvent<HTMLInputElement>) {
      console.log(e);
      const value = e.currentTarget.value;
      if (e.key === 'Backspace' && (value === undefined || value === '')) {
        const sortedIndices = [...ref.current.keys()].sort();
        const prevIndex = sortedIndices[sortedIndices.indexOf(index) - 1];
        if (typeof prevIndex === 'number') ref.current.get(prevIndex)?.focus();
      }
    },
    onPaste(e: ClipboardEvent<HTMLInputElement>, setValue: UseFormSetValue<any>) {
      const value = e.clipboardData.getData('text');
      e.preventDefault();
      if (value && value.length > 0) {
        const sortedIndices = [...ref.current.keys()].sort();
        const codes = value.split('');

        const current = ref.current.get(index);
        if (current) setValue(current.name, codes.shift());

        codes.forEach((code, idx) => {
          const nextIndex = sortedIndices[sortedIndices.indexOf(index) + 1 + idx];
          if (typeof nextIndex === 'number') {
            const next = ref.current.get(nextIndex);
            if (next) setValue(next.name, code);
            next?.focus();
          }
        });
      }
    },
    ref(element: HTMLInputElement | null) {
      if (element) ref.current.set(index, element);
      else ref.current.delete(index);
    },
  });
};
