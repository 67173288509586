import { AppState, Auth0Provider } from '@auth0/auth0-react';
import * as React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as yup from 'yup';

import { enableMock } from '@/__mocks__/axiosMock';
import { Spinner, SimpleCard } from '@/components/Elements';
import { Title } from '@/components/Elements/Title/Title';
import { Container } from '@/components/Layout';
//import { ProtectedLayout } from '@/components/Layout/ProtectedLayout';
import { PublicLayout } from '@/components/Layout/PublicLayout';
import { formErrorMessage } from '@/lib/formErrorMessage';
import { isMocked } from '@/lib/isMocked';
import { queryClient } from '@/lib/react-query';
import { customValidator } from '@/lib/yup-definition';

import { AUTH0_CLIENT_ID, AUTH0_URL } from '../config';

export const authInfo = {
  URL: AUTH0_URL,
  CLIENT_ID: AUTH0_CLIENT_ID,
};

const ErrorFallback = ({ error }: FallbackProps) => {
  return (
    <PublicLayout>
      <SimpleCard>
        <Title className="text-center">エラー</Title>
        <Container>
          <div className="" role="alert">
            <p className="py-4">エラーが発生しました。しばらく経ってからやりなおしてください。</p>
            <p className="py-4">エラー内容：{error.message}</p>
          </div>
        </Container>
      </SimpleCard>
    </PublicLayout>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  if (isMocked()) {
    // apiモック利用
    enableMock();
  }
  // yup
  customValidator();
  // yupの日本語対応
  yup.setLocale(formErrorMessage);

  const onRedirectCallback = (appState?: AppState | undefined) => {
    let href = appState?.returnTo || window.location.pathname;
    href = appState?.returnTo || window.location.pathname;

    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get('url');

    if (searchParam === 'password_email_update_redirect') {
      href = '/app/mypage';
    }

    window.location.href = href;
  };

  return (
    <Auth0Provider
      domain={authInfo.URL}
      clientId={authInfo.CLIENT_ID}
      redirectUri={
        window.location.hostname === 'localhost'
          ? `${window.origin}/callback`
          : `${window.origin}/callback/`
      }
      onRedirectCallback={onRedirectCallback}
    >
      <React.Suspense
        fallback={
          <div className="flex items-center justify-center w-screen h-screen">
            <Spinner size="xl" />
          </div>
        }
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
              {children}
            </QueryClientProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </React.Suspense>
    </Auth0Provider>
  );
};
