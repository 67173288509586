import _ from 'lodash';
import { useEffect, useState } from 'react';

import { Card } from '@/components/Elements';
import { Bread } from '@/components/Elements/Bread/Bread';
import { ToggleButton } from '@/components/Elements/Button/ToggleButton';
import { Line } from '@/components/Elements/Line/Line';
import { Pagination } from '@/components/Elements/Pagination/Pagination';
import { SelectFilter } from '@/components/Elements/Select/SelectFilter';
import { Title } from '@/components/Elements/Title/Title';
import { Container, Row } from '@/components/Layout';
import { ProtectedLayout } from '@/components/Layout/ProtectedLayout';

import { Notice } from '../db/db';
import { useNotices } from '../hooks/useNotices';

import { InfoListItem } from './InfoListItem';

export type EvcListType = {
  evcList: string[];
};

export const InfoList = () => {
  // hook
  const { refetch, read, updateTimeStamp } = useNotices();

  // state mount時のタイムスタンプを保持
  const [mountTimeStamp] = useState(new Date());

  // state お知らせ一覧
  const [notices, setNotices] = useState<Notice[]>([]);

  // effect mount時にサーバとindexedDBの同期
  useEffect(() => {
    refetch();
  }, [refetch]);

  // mount or 同期のタイミングで、お知らせ一覧をindexedDBより取得し、stateにset
  useEffect(() => {
    const asyncFunc = async () => {
      read().then((res) => {
        if (res) setNotices(res);
      });
    };

    if (updateTimeStamp && mountTimeStamp < updateTimeStamp) {
      asyncFunc();
    }
  }, [mountTimeStamp, read, updateTimeStamp]);

  // callback stateの既読フラグを更新
  // const updateNoticesState = useCallback(
  //   (notificationId: string) => {
  //     const _notices = _.cloneDeep(notices);
  //     const _notice = _notices.find((x) => x.notificationId === notificationId);
  //     if (_notice) _notice.isRead = '1';
  //     setNotices(_notices);
  //   },
  //   [notices]
  // );

  // 絞り込み後お知らせリスト
  const [afterList, setAfterList] = useState<Notice[]>([]);

  // state 承認ステータスFilter
  const [typeFilter, setTypeFilter] = useState('0');

  // state 未読Filter
  const [isReadFilter, setIsReadFilter] = useState(false);

  useEffect(() => {
    let cloneData = _.cloneDeep(notices);
    if (typeFilter !== '0') {
      cloneData = cloneData.filter((x) => {
        return x.notificationType === Number(typeFilter);
      });
    }

    if (isReadFilter) {
      cloneData = cloneData.filter((x) => {
        return x.isRead === '0';
      });
    }
    setAfterList(cloneData);
  }, [isReadFilter, notices, typeFilter]);

  // 表示ページのお知らせリスト
  const [currentItems, setCurrentItems] = useState<Notice[]>([]);

  return (
    <>
      <ProtectedLayout>
        <Bread array={[{ label: 'お知らせ', path: '', now: true }]} />
        <Card>
          <div className="flex">
            <Title isHr={false} className="!mr-12">
              お知らせ
            </Title>
            <a
              href={infoOutLink}
              className="my-auto underline text-sm"
              target="_blank"
              rel="noreferrer"
            >
              運営からのお知らせはこちら（メンテナンス情報など）
            </a>
          </div>
          <Line mx="mx-6" />
          <Container py="py-6">
            <div className="flex w-full justify-between">
              <div className="selectors grid grid-flow-col gap-x-6">
                <ToggleButton
                  leftName="すべて"
                  rightName="未読"
                  onClick={(isOpen: boolean) => {
                    setIsReadFilter(!isOpen);
                  }}
                />
                <SelectFilter
                  options={[
                    { label: 'すべての分類', value: '0' },
                    { label: '予約通知', value: '2' },
                    { label: 'お知らせ', value: '1' },
                  ]}
                  onChange={(e) => {
                    setTypeFilter(e);
                  }}
                />
              </div>
            </div>
            <div className="py-6">
              {currentItems.length === 0 ? (
                <p>お知らせがありません</p>
              ) : (
                <>
                  <Row>
                    {currentItems.map((item, index) => {
                      return <InfoListItem key={`${item.notificationId}-${index}`} notice={item} />;
                    })}
                  </Row>
                </>
              )}
              <nav className="flex pt-6 justify-end">
                <Pagination items={afterList} itemsPerPage={10} setCurrentItems={setCurrentItems} />
              </nav>
            </div>
          </Container>
        </Card>
      </ProtectedLayout>
    </>
  );
};

const infoOutLink =
  'https://support.charger-share.everiwa.jp/hc/ja/sections/16262872256153-%E9%81%8B%E5%96%B6%E3%81%8B%E3%82%89%E3%81%AE%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B';
