export const SimpleCard = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="rounded shadow w-full sc-desktop:w-simple-card mx-auto mt-5 overflow-hidden bg-white">
      <div
        className="border-t-[10px]"
        style={{ borderImage: 'linear-gradient(to left,#364563,#407DBF 80%,#40BF8E)1' }}
      ></div>
      {children}
    </div>
  );
};
