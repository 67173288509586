import { Callback } from '@/features/callback';
import { UserEmailConfirm } from '@/features/user';

const Top = () => {
  window.location.href = '/app/evc';
  console.log('redirect');

  return <></>;
};

const LogoutCallback = () => {
  console.log('redirect');

  const searchParams = new URLSearchParams(window.location.search);
  const searchParam = searchParams.get('url');

  if (searchParam === 'password_email_update_redirect') {
    window.location.href = '/app/mypage';
  } else {
    window.location.href = '/app/evc';
  }

  return <></>;
};

export const publicRoutes = [
  {
    path: '/',
    element: <Top />,
  },
  {
    path: '',
    element: <Callback />,
  },
  {
    path: '/callback/*',
    element: <Callback />,
  },
  {
    path: '/logout_callback/*',
    element: <LogoutCallback />,
  },
  {
    path: '/user/email',
    element: <UserEmailConfirm />,
  },
];
