import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';
import { UnitLabel } from './UnitLabel';

type NumberFieldProps = FieldWrapperPassThroughProps & InnerNumberFieldProps;

export const NumberField = (props: NumberFieldProps) => {
  const {
    step,
    className,
    label,
    registration,
    error,
    defaultValue,
    minValue,
    maxValue,
    required,
    unit,
    placeholder,
  } = props;
  return (
    <FieldWrapper label={label} error={error} required={required}>
      {unit ? (
        <UnitLabel unit={unit}>
          <InnerNumberField
            step={step}
            className={clsx('form-input w-full', className)}
            defaultValue={defaultValue}
            registration={registration}
            minValue={minValue}
            maxValue={maxValue}
            placeholder={placeholder}
          />
        </UnitLabel>
      ) : (
        <InnerNumberField
          step={step}
          className={clsx('form-input w-full', className)}
          defaultValue={defaultValue}
          registration={registration}
          minValue={minValue}
          maxValue={maxValue}
          placeholder={placeholder}
        />
      )}
    </FieldWrapper>
  );
};

type InnerNumberFieldProps = {
  step?: number;
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  defaultValue?: number;
  minValue?: number;
  maxValue?: number;
  unit?: string;
  placeholder?: string;
};

const numberStrs = '0123456789-.'.split('');

const InnerNumberField = (props: InnerNumberFieldProps) => {
  const { step, className, registration, defaultValue, minValue, maxValue, placeholder } = props;

  const quantityInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const ignoreScroll = function () {
      quantityInputRef.current && quantityInputRef.current.blur();
    };
    quantityInputRef.current && quantityInputRef.current.addEventListener('wheel', ignoreScroll);
    return function cleanup() {
      quantityInputRef.current &&
        quantityInputRef.current.removeEventListener('wheel', ignoreScroll);
    };
  }, [quantityInputRef]);

  return (
    <input
      type="tel"
      step={step}
      className={clsx('form-input w-full', className)}
      defaultValue={defaultValue}
      name={registration.name}
      ref={(e) => {
        registration.ref && registration.ref(e);
        quantityInputRef.current = e;
      }}
      onChange={registration.onChange}
      onBlur={registration.onBlur}
      onKeyDown={(e) => {
        if (e.key.length > 1) return;
        if (!numberStrs.includes(e.key)) e.preventDefault();
      }}
      min={minValue}
      max={maxValue}
      placeholder={placeholder}
    />
  );
};
