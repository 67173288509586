import { FC, useEffect, useState } from 'react';

import { getPhotoS3 } from '@/api/_common/getPhotoS3';
import { JpegBase64Prefix } from '@/lib/JpegBase64Prefix';

type SquareImageViewProps = {
  url: string;
  length: number;
};

export const SquareImageView: FC<SquareImageViewProps> = ({ url, length }) => {
  const [base64Image, setBase64Image] = useState('');

  useEffect(() => {
    const asyncSetBase64Image = async () => {
      getPhotoS3(url).then((res) => {
        if (res.data) {
          setBase64Image(JpegBase64Prefix + res.data);
        }
      });
    };
    asyncSetBase64Image();
  }, [url]);

  const sizeClass = getSizeClass(length);

  return base64Image ? (
    <SquareImageViewOnly src={base64Image} length={length} />
  ) : (
    <div className={`flex items-center justify-center rounded shadow bg-gray-200 ${sizeClass}`}>
      <p className={`text-gray-500 text-center`}>NoImage</p>
    </div>
  );
};

type SquareImageViewOnly = { src: string; length: number };

export const SquareImageViewOnly: FC<SquareImageViewOnly> = (props) => {
  const { src, length, children } = props;
  const sizeClass = getSizeClass(length);
  return (
    <>
      <div className={`relative rounded shadow bg-black ${sizeClass}`}>
        {children}
        <img className={`object-contain ${sizeClass}`} src={src} alt="" />
      </div>
    </>
  );
};

const getSizeClass = (length: number): string => {
  switch (length) {
    case 11: {
      return 'w-11 h-11';
    }
    case 24: {
      return 'w-24 h-24';
    }
    case 36: {
      return 'w-36 h-36';
    }
    case 60: {
      return 'w-60 h-60';
    }
    default: {
      return 'w-36 h-36';
    }
  }
};
