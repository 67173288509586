import clsx from 'clsx';
import * as React from 'react';

const variants = {
  primary: 'bg-main-700 text-white hover:bg-gray-50:text-blue-600',
  inverse: 'border border-main-700 bg-white text-main-700 hover:bg-blue-600:text-white',
  borderless: 'shadow-none',
};

const sizes = {
  sm: 'h-10',
  base: 'h-13 w-button',
  lg: 'h-13 w-button-lg',
  square: 'h-14 w-16',
  inline: 'h-14 w-40',
  'form-inline': 'h-14 w-40 mt-form-inline',
};

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
  inline?: boolean;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = 'rounded',
      variant = 'primary',
      size = 'base',
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          'flex justify-center items-center disabled:opacity-70 disabled:cursor-not-allowed shadow font-medium',
          variants[variant],
          sizes[size],
          className
        )}
        {...props}
      >
        {/* {isLoading && <Spinner size="sm" className="text-current" />} */}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = 'Button';
