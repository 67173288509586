import { useEffect, useState } from 'react';

import { getPhotoS3 } from '@/api/_common/getPhotoS3';
import { JpegBase64Prefix } from '@/lib/JpegBase64Prefix';

import { UserIcon } from '../Icon/UserIcon';

export const ImageView = ({
  url,
  className = '',
  //defaultSrc = '',
  isUserIcon = false,
}: {
  url: string;
  className?: string;
  //defaultSrc?: string;
  isUserIcon?: boolean;
}) => {
  const [base64Image, setBase64Image] = useState('');

  useEffect(() => {
    const asyncSetBase64Image = async () => {
      getPhotoS3(url).then((res) => {
        if (res.data) {
          setBase64Image(JpegBase64Prefix + res.data);
        }
      });
    };
    asyncSetBase64Image();
  }, [url]);

  return isUserIcon ? (
    base64Image ? (
      <img className={className} src={base64Image} alt="" />
    ) : (
      <UserIcon className={className} />
    )
  ) : base64Image ? (
    <img className={className} src={base64Image} alt="" />
  ) : (
    <div>NoImage</div>
  );
};
