import Dexie, { Table } from 'dexie';
import { useEffect, useState } from 'react';

import { useUserRefApiQuery } from '../api/useUserRefApiQuery';

export interface Notice {
  notificationId: string;
  notificationType: number;
  sendDatetime: Date;
  title: string;
  content: string;
  isRead: string;
}

export class MyNoticeClassedDexie extends Dexie {
  notices!: Table<Notice>;

  constructor(userId: string) {
    super(`everiwaNoticesDatabase-${userId}`);
    this.version(1).stores({
      notices: '&notificationId, notificationType, sendDatetime, title, content, confirmed, isRead', // Primary key and indexed props
    });
  }
}

export const useSingleton = () => {
  const [db, setDb] = useState<MyNoticeClassedDexie>();
  const { data } = useUserRefApiQuery(undefined);

  useEffect(() => {
    if (!db && data?.dispUserId) {
      const db = new MyNoticeClassedDexie(data?.dispUserId);
      setDb(db);
    }
  }, [data?.dispUserId, db]);

  return db;
};
