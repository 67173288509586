import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/map_listref_api').reply((config) => {
    console.log(JSON.stringify(config.data));
    return [
      200,
      {
        result: 0,
        err: [
          {
            errCode: 0,
            errMsg: '',
          },
        ],
        data: {
          listNum: 1,
          listData: [
            {
              evcEquipmentId: '1',
              evcLongitude: '139.0347',
              evcLatitude: '36.2322',
              hostPlaceName: '場所1',
              evcNickName: '設備1',
              evcPhotoUrl: 'https://wallpaperaccess.com/full/317501.jpg',
              reservationFlag: false,
              existCoupon: true,
              maxFeeInfomation: 5000,
              minFeeInfomation: 100,
              maxAvailableCarType: 500,
              voltage: 1,
              outputVoltage: 1.5,
            },
            {
              evcEquipmentId: '2',
              evcLongitude: '130.39280760952627',
              evcLatitude: '33.59386842430587',
              hostPlaceName: '場所A',
              evcNickName: '設備A',
              evcPhotoUrl: 'https://wallpaperaccess.com/full/119615.jpg',
              reservationFlag: true,
              existCoupon: true,
              maxFeeInfomation: 500,
              minFeeInfomation: 100,
              maxAvailableCarType: 500,
              voltage: 1,
              outputVoltage: 1.5,
            },
            {
              evcEquipmentId: '3',
              evcLongitude: '130.39446521469864',
              evcLatitude: '33.59365840665113',
              hostPlaceName: '場所B',
              evcNickName: '設備B',
              evcPhotoUrl: 'https://wallpaperaccess.com/full/793.png',
              reservationFlag: false,
              existCoupon: true,
              maxFeeInfomation: 300,
              minFeeInfomation: 100,
              maxAvailableCarType: 500,
              voltage: 1,
              outputVoltage: 1.5,
            },
          ],
        },
      },
    ];
  });
};
