import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

// import * as UserCarListRef from './user_car_listref/error'
import * as CheckInSuccess from './checkin/success';
import * as CheckOutSuccess from './checkout/success';
import * as CiUserRefSuccess from './ci_user_ref/success';
import * as EvcAddSuccess from './evc_add/success';
import * as EvcDeleteSuccess from './evc_delete/success';
import * as EvcDetailAddSuccess from './evc_detail_add/success';
import * as EvcDetailBaseRefSuccess from './evc_detail_base_ref/success';
import * as EvcDetailBaseUpdateSuccess from './evc_detail_base_update/success';
import * as EvcDetailFeeRefSuccess from './evc_detail_fee_ref/success';
import * as EvcDetailFeeUpdateSuccess from './evc_detail_fee_update/success';
import * as EvcDetailRefSuccess from './evc_detail_ref/success';
import * as EvcDetailReserveRefSuccess from './evc_detail_reserve_ref/success';
import * as EvcDetailReserveUpdateSuccess from './evc_detail_reserve_update/success';
import * as EvcListRefSuccess from './evc_listref/success';
import * as EvcPlaceListRefSuccess from './evc_place_listref/success';
import * as EvcRefSuccess from './evc_ref/success';
import * as EvcUpdateSuccess from './evc_update/success';
import * as MapListRefSuccess from './map_listref/success';
import * as RsvUserAddSuccess from './rsv_user_add/success';
import * as UserListRefSuccess from './rsv_user_listref/success';
import * as RsvUserRefempSuccess from './rsv_user_refemp/success';
// import * as UserQuitError from './user_quit/error'
// import * as UpdateEmail from './user_email_update/error'
// import * as UserMypageRef from './user_mypage_ref/error'
import * as rsvFeeCalc from './rsvFeeCalc/success';
import * as S3ImageUpload from './s3ImageUpload/success';
import * as UserAddSuccess from './user_add/success';
import * as UserCancelQuitSuccess from './user_cancel_quit/success';
import * as UserCarAddSuccess from './user_car_add/success';
import * as UserCarDeleteSuccess from './user_car_delete/success';
import * as UserCarListRef from './user_car_listref/success';
import * as UserCarUpdateSuccess from './user_car_update/success';
import * as UpdateEmail from './user_email_update/success';
import * as UserMypageRefSuccess from './user_mypage_ref/success';
import * as UserQuitSuccess from './user_quit/success';
import * as UserRefSuccess from './user_ref/success';
import * as userRefStatusSuccess from './user_ref_status/success';
import * as UserTokenSetSuccess from './user_token_set/error';
import * as UserUpdateSuccess from './user_update/success';
// import * as rsvFeeCalc from './rsvFeeCalc/error'

export const enableMock = () => {
  const mock = new MockAdapter(axios);

  UserAddSuccess.registerMock(mock);
  UserRefSuccess.registerMock(mock);
  UserUpdateSuccess.registerMock(mock);
  UserCarAddSuccess.registerMock(mock);
  UserCarUpdateSuccess.registerMock(mock);
  UserCarDeleteSuccess.registerMock(mock);
  UserCarListRef.registerMock(mock);
  UserCarDeleteSuccess.registerMock(mock);
  UserCarListRef.registerMock(mock);
  EvcAddSuccess.registerMock(mock);
  EvcUpdateSuccess.registerMock(mock);
  EvcDeleteSuccess.registerMock(mock);
  EvcListRefSuccess.registerMock(mock);
  EvcPlaceListRefSuccess.registerMock(mock);
  EvcRefSuccess.registerMock(mock);
  EvcDetailAddSuccess.registerMock(mock);
  EvcDetailRefSuccess.registerMock(mock);
  EvcDetailBaseRefSuccess.registerMock(mock);
  EvcDetailBaseUpdateSuccess.registerMock(mock);
  EvcDetailReserveRefSuccess.registerMock(mock);
  EvcDetailReserveUpdateSuccess.registerMock(mock);
  EvcDetailFeeRefSuccess.registerMock(mock);
  EvcDetailFeeUpdateSuccess.registerMock(mock);
  MapListRefSuccess.registerMock(mock);
  RsvUserRefempSuccess.registerMock(mock);
  RsvUserAddSuccess.registerMock(mock);
  CheckInSuccess.registerMock(mock);
  CheckOutSuccess.registerMock(mock);
  CiUserRefSuccess.registerMock(mock);
  UserTokenSetSuccess.registerMock(mock);
  UserListRefSuccess.registerMock(mock);
  UserQuitSuccess.registerMock(mock);
  // UserQuitError.registerMock(mock)
  UserCancelQuitSuccess.registerMock(mock);
  userRefStatusSuccess.registerMock(mock);
  UpdateEmail.registerMock(mock);
  UserMypageRefSuccess.registerMock(mock);
  // UserMypageRefError.registerMock(mock)
  rsvFeeCalc.registerMock(mock);
  S3ImageUpload.registerMock(mock);
};
