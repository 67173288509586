import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type UserHctermsUpdateCheckApiQuery = {
  termsReconfirmed: boolean;
  description: string;
};

export const UserHctermsUpdateCheckApiErrType = {
  0: 'エラー無し',
  1: '該当ユーザーなし',
  2: '規約取得失敗',
  50: 'HCリフレッシュトークン有効期限切れ',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  300: 'ハウスコインAPI異常',
  400: 'API端末認証異常',
  999: 'システムエラー',
};

type UserHctermsUpdateCheckApiErrTypeKey = keyof typeof UserHctermsUpdateCheckApiErrType;

export const useUserHctermsUpdateCheckApiQuery = <TData = UserHctermsUpdateCheckApiQuery>(
  variables: undefined,
  options?: UseQueryOptions<
    UserHctermsUpdateCheckApiQuery,
    ApiError<UserHctermsUpdateCheckApiErrTypeKey>,
    TData
  >
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useQuery<
    UserHctermsUpdateCheckApiQuery,
    ApiError<UserHctermsUpdateCheckApiErrTypeKey>,
    TData
  >(
    ['user_hcterms_update_check_api', variables],
    useFetchData<
      UserHctermsUpdateCheckApiQuery,
      ApiError<UserHctermsUpdateCheckApiErrTypeKey>,
      undefined
    >('user_hcterms_update_check_api').bind(null, variables),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert('アクセスに失敗しました。再度ログインしてください。');
              break;
            case 2:
              alert('問題が発生しました。(S200E002)\n問い合わせフォームよりご連絡ください。');
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
