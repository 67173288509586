import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';

import { ConfirmProvider } from './components/Confirm';
import './style/style.scss';
import './style/common.scss';
import 'react-tooltip/dist/react-tooltip.css';

function App() {
  return (
    <ConfirmProvider>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </ConfirmProvider>
  );
}

export default App;
