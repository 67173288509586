import { isBefore, subDays } from 'date-fns';
import * as yup from 'yup';

import { formErrorMessage } from '@/lib/formErrorMessage';
import {
  REQUIRED,
  formatMessage,
  STR_MAX,
  HANKAKU_NUM_HYPHEN,
  ZENKAKU_KANA,
  ZENKAKU_KANA_MAX_WITH,
  ZENKAKU_MAX_WITH,
  ZENKAKU_NOT_SPACE,
  NOTTYPE,
} from '@/lib/messages';
import { checkPostCode, customValidator } from '@/lib/yup-definition';

// yup
customValidator();
// yupの日本語対応
yup.setLocale(formErrorMessage);

export const schemaUserAdd = yup.object().shape({
  nickName: yup.string().trim().required().max(12),
  surname: yup
    .string()
    .trim()
    .required()
    .maxWith('name', 30, formatMessage(ZENKAKU_MAX_WITH, '氏名', '30'))
    .zenkakuInput(
      { notEmoji: true, notWhiteSpace: true },
      formatMessage(ZENKAKU_MAX_WITH, '氏名', '30')
    ),
  name: yup
    .string()
    .zenkakuInput(
      { trim: true, required: true, max: 64, notEmoji: true, notWhiteSpace: true },
      formatMessage(ZENKAKU_MAX_WITH, '氏名', '30')
    ),
  surnameRuby: yup
    .string()
    .trim()
    .required()
    .maxWith('nameRuby', 25, formatMessage(ZENKAKU_KANA_MAX_WITH, '氏名', '25'))
    .katakana(formatMessage(ZENKAKU_KANA_MAX_WITH, '氏名', '25')),
  nameRuby: yup
    .string()
    .trim()
    .required()
    .max(128, formatMessage(ZENKAKU_KANA_MAX_WITH, '氏名', '25'))
    .katakana(formatMessage(ZENKAKU_KANA_MAX_WITH, '氏名', '25')),
  postCode: checkPostCode(),
  prefectures: yup.string().required(),
  municipalities: yup.string().zenkakuInput({
    required: true,
    trim: true,
    max: 512,
    notEmoji: true,
    notWhiteSpace: true,
  }),
  address: yup
    .string()
    .trim()
    .required(REQUIRED)
    .max(512, formatMessage(STR_MAX, 512))
    .matches(/^[0-9]+(-[0-9]+)*$/, HANKAKU_NUM_HYPHEN),
  buildingName: yup.string().trim().max(512, formatMessage(STR_MAX, 512)),
  birthYear: yup
    .string()
    .required()
    .test(
      'birthdayMonth',
      '有効な日付を入力してください',
      function (yearValue: string | undefined) {
        const monthValue = this.parent.birthdayMonth;
        const dateValue = this.parent.birthdayDate;
        if (!yearValue) return true;
        if (!monthValue) return true;
        if (!dateValue) return true;

        const d = new Date(Number(yearValue), Number(monthValue) - 1, Number(dateValue));
        if (d.getMonth() + 1 != monthValue) {
          return false;
        }

        const today = new Date();

        if (isBefore(subDays(today, 1), d)) {
          return false;
        }

        return true;
      }
    ),
  birthMonth: yup.string().required(),
  birthDate: yup.string().required(),
  hostRegistrationNumber: yup
    .string()
    .test('hostRegistrationNumber', NOTTYPE, function (hostRegistrationNumber: string | undefined) {
      if (hostRegistrationNumber) {
        const first = hostRegistrationNumber.substring(0, 1);
        // 先頭"T"
        if (first !== 'T') return false;

        const remain = hostRegistrationNumber.substring(1);
        const regexp = new RegExp(/^[0-9]{13}$/);
        // 数字13桁
        if (!regexp.test(remain)) return false;
      }
      return true;
    }),
  userKind: yup.boolean().required(),
  corporateName: yup
    .string()
    .trim()
    .when('userKind', (value, schema) => {
      return value ? schema.required() : schema;
    })
    .max(128)
    .zenkakuInput({ notEmoji: true, notWhiteSpace: true }, ZENKAKU_NOT_SPACE),
  corporateNameRuby: yup
    .string()
    .trim()
    .when('userKind', (value, schema) => {
      return value ? schema.required() : schema;
    })
    .max(128)
    .katakana(ZENKAKU_KANA),
});
