import { Routes } from '@/components/Routes';

import { UserAccountComplete } from './UserAccountComplete';
import { UserAdd } from './UserAdd';

export const userRoutes = [
  {
    title: '会員登録',
    path: '/add',
    element: <UserAdd />,
  },
  {
    title: 'アカウント登録完了',
    path: '/account',
    element: <UserAccountComplete />,
  },
];

export const UserRoutes = () => {
  return <Routes routes={userRoutes} />;
};
