import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type UserTermsPfComfirmApiQuery = {
  updateFlag: boolean;
  version: string;
  pfTermsUrl: string;
};

export const UserTermsPfComfirmApiErrType = {
  0: 'エラー無し',
  1: '該当ユーザー無',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

type UserTermsPfComfirmApiErrTypeKey = keyof typeof UserTermsPfComfirmApiErrType;

export const useUserTermsPfComfirmApiQuery = <TData = UserTermsPfComfirmApiQuery>(
  variables: undefined,
  options?: UseQueryOptions<
    UserTermsPfComfirmApiQuery,
    ApiError<UserTermsPfComfirmApiErrTypeKey>,
    TData
  >
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useQuery<UserTermsPfComfirmApiQuery, ApiError<UserTermsPfComfirmApiErrTypeKey>, TData>(
    ['user_terms_pf_confirm_api', variables],
    useFetchData<UserTermsPfComfirmApiQuery, ApiError<UserTermsPfComfirmApiErrTypeKey>, undefined>(
      'user_terms_pf_confirm_api'
    ).bind(null, variables),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert('アクセスに失敗しました。再度ログインしてください。');
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
