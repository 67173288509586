import { FC, ReactNode, createContext } from 'react';

import { TermsDialog } from '../components/TermsDialog';

type TermsContextProps = { open: () => void };

const TermsContext = createContext<TermsContextProps | undefined>(undefined);

type TermsProviderProps = { children?: ReactNode };

export const TermsProvider: FC<TermsProviderProps> = (props) => {
  return (
    <TermsContext.Provider value={{ open }}>
      {props.children}
      <TermsDialog />
    </TermsContext.Provider>
  );
};
