export const UserLineIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g id="icon_user_line">
        <g transform="translate(2.999 1.999)">
          <g clipPath="url(#clip-path)">
            <path
              d="M8.989.75A4.249,4.249,0,1,1,4.761,5,4.238,4.238,0,0,1,8.989.75Z"
              transform="translate(0.002 0.003)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              d="M.75,20a8.248,8.248,0,0,1,16.5,0"
              transform="translate(0.002 0.003)"
              fill="none"
              stroke="#fff"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
