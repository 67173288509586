import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/evc_ref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      evcEquipmentId: '1',
      evcEquipmentName: 'ヤマダの設備',
      evcPlaceName: '博多駅〇〇ビル',
      evcCategory: 1,
      placePhoto: [
        {
          photoName: 'photo01',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PLACE/place01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=9996b6225fedaacadbb36cba1dced76fde3a11cb3f449832f0381d79dc3bbcd4',
        },
      ],
      postCode: '8120012',
      prefectures: '福岡県',
      municipalities: '福岡市博多区博多駅・・・',
      address: '1-1',
      buildingName: '〇〇ビル201',
      evcLongitude: '139.0347',
      evcLatitude: '36.2322',
      equipmentPhoto: [
        {
          photoName: 'photo01',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment01?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECQaDmFwLW5vcnRoZWFzdC0xIkgwRgIhAJBpOzIQ2B683yX7CUPwvPYcfhq9B3KEKYav4tv4S4GxAiEA4IoPr7ijqjjvRBELYcDi6MEs11j6zDR0r8MRGVP2GfYq%2BwIIPhABGgw5MDQyMDEyOTUwOTUiDCU%2But4kyf3C43R2uirYAt8SW8Nhzj9DC1UxulCClz9Kw9%2FXJAs2B2FFOpv3LQacAo9%2Fc%2BWXSj%2B1n7AioZqXhp9vcRFspU0a%2BjyOK9ys9WxMNerqyKRpzR0ckzor0Q0Rdot9c%2FbRBgmMLJR3cMcvIVN8z9UfaLEQvSSy4uSZ5PH3MMjWxs205ItR9qG%2FfLW1s9Csxo9%2BIr%2FJyptHa6hbV9X%2FTIpTEjX2tMQcPaxP4FRCpJnNLtNfKn87c2B7rEX80wzNY8Ehng%2Bf%2Fr%2Fg3Sxk8BxjMvXvSobuFdrA62rMqEbre3%2B3YNjQhaSXX9V%2BNABhlivrB9xTUGdK%2BLhPC6CyJwOM63mMotpfVKu00UuIy5jw57t5iK364%2BviphqFoPoEjDDq%2F%2Bf5g71rMuL1OpD5zGZW8p9OVisEIiX43NpPzk4TgWnuxF9fE7%2BXEAwUKlFbj6uDce7MbCBGGxuUQQhnFLQBzU%2BapvvmMNGVxZUGOrICOt6ieUGyOHG7dQZn4N9GcsMcYFgpgUsLqkkeXwe0slpzIhs%2B5294phB0cW%2FCiNRFoSj%2BLfbVNfigrh2zED9iD9eeNwQ94G%2FzZbV88afm%2F4C%2BBvuQOa9fvytwC%2BSsREaB3kL%2Bbtkax0x2jHqKpYhiSZGmn16pg6tUexFNte1pbl6%2FUo9q0S8W8tqIBHBIj9h0z4AT0YFaf4QpA%2FJaK94b27RSAE27BKE%2FCXQ0Ee%2Fv57Oqys1B%2FjDea7kp6aKpQ8xQc0f9qgCzGDQ6zLBT761oGWMo1PgQ93jtzFI3bZV%2BthDgOXKasO5MvaEL9Gut8OO%2BZT893POP7X1LcoiFq9ZWHCOs5NsohSB8F%2FiV7e25jWFSvJEbDs1P7TU5RBhWiWzPTn9AhE26YgXAehTnH0ZUzPC5&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220621T055120Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIA5FBUY2T3QVOTH2ES%2F20220621%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=6cf0c54fd95789729be5ee96a3278038819de30ca19f17fd512e12ad79b36f38',
        },
        {
          photoName: 'photo02',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment02?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECQaDmFwLW5vcnRoZWFzdC0xIkgwRgIhAJBpOzIQ2B683yX7CUPwvPYcfhq9B3KEKYav4tv4S4GxAiEA4IoPr7ijqjjvRBELYcDi6MEs11j6zDR0r8MRGVP2GfYq%2BwIIPhABGgw5MDQyMDEyOTUwOTUiDCU%2But4kyf3C43R2uirYAt8SW8Nhzj9DC1UxulCClz9Kw9%2FXJAs2B2FFOpv3LQacAo9%2Fc%2BWXSj%2B1n7AioZqXhp9vcRFspU0a%2BjyOK9ys9WxMNerqyKRpzR0ckzor0Q0Rdot9c%2FbRBgmMLJR3cMcvIVN8z9UfaLEQvSSy4uSZ5PH3MMjWxs205ItR9qG%2FfLW1s9Csxo9%2BIr%2FJyptHa6hbV9X%2FTIpTEjX2tMQcPaxP4FRCpJnNLtNfKn87c2B7rEX80wzNY8Ehng%2Bf%2Fr%2Fg3Sxk8BxjMvXvSobuFdrA62rMqEbre3%2B3YNjQhaSXX9V%2BNABhlivrB9xTUGdK%2BLhPC6CyJwOM63mMotpfVKu00UuIy5jw57t5iK364%2BviphqFoPoEjDDq%2F%2Bf5g71rMuL1OpD5zGZW8p9OVisEIiX43NpPzk4TgWnuxF9fE7%2BXEAwUKlFbj6uDce7MbCBGGxuUQQhnFLQBzU%2BapvvmMNGVxZUGOrICOt6ieUGyOHG7dQZn4N9GcsMcYFgpgUsLqkkeXwe0slpzIhs%2B5294phB0cW%2FCiNRFoSj%2BLfbVNfigrh2zED9iD9eeNwQ94G%2FzZbV88afm%2F4C%2BBvuQOa9fvytwC%2BSsREaB3kL%2Bbtkax0x2jHqKpYhiSZGmn16pg6tUexFNte1pbl6%2FUo9q0S8W8tqIBHBIj9h0z4AT0YFaf4QpA%2FJaK94b27RSAE27BKE%2FCXQ0Ee%2Fv57Oqys1B%2FjDea7kp6aKpQ8xQc0f9qgCzGDQ6zLBT761oGWMo1PgQ93jtzFI3bZV%2BthDgOXKasO5MvaEL9Gut8OO%2BZT893POP7X1LcoiFq9ZWHCOs5NsohSB8F%2FiV7e25jWFSvJEbDs1P7TU5RBhWiWzPTn9AhE26YgXAehTnH0ZUzPC5&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220621T062346Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIA5FBUY2T3QVOTH2ES%2F20220621%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=8e989d9f90da3d753fb53382e171c9d8b923dbc47ba90d60ff5c404c195f1e62',
        },
        {
          photoName: 'photo03',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment03?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECQaDmFwLW5vcnRoZWFzdC0xIkgwRgIhAJBpOzIQ2B683yX7CUPwvPYcfhq9B3KEKYav4tv4S4GxAiEA4IoPr7ijqjjvRBELYcDi6MEs11j6zDR0r8MRGVP2GfYq%2BwIIPhABGgw5MDQyMDEyOTUwOTUiDCU%2But4kyf3C43R2uirYAt8SW8Nhzj9DC1UxulCClz9Kw9%2FXJAs2B2FFOpv3LQacAo9%2Fc%2BWXSj%2B1n7AioZqXhp9vcRFspU0a%2BjyOK9ys9WxMNerqyKRpzR0ckzor0Q0Rdot9c%2FbRBgmMLJR3cMcvIVN8z9UfaLEQvSSy4uSZ5PH3MMjWxs205ItR9qG%2FfLW1s9Csxo9%2BIr%2FJyptHa6hbV9X%2FTIpTEjX2tMQcPaxP4FRCpJnNLtNfKn87c2B7rEX80wzNY8Ehng%2Bf%2Fr%2Fg3Sxk8BxjMvXvSobuFdrA62rMqEbre3%2B3YNjQhaSXX9V%2BNABhlivrB9xTUGdK%2BLhPC6CyJwOM63mMotpfVKu00UuIy5jw57t5iK364%2BviphqFoPoEjDDq%2F%2Bf5g71rMuL1OpD5zGZW8p9OVisEIiX43NpPzk4TgWnuxF9fE7%2BXEAwUKlFbj6uDce7MbCBGGxuUQQhnFLQBzU%2BapvvmMNGVxZUGOrICOt6ieUGyOHG7dQZn4N9GcsMcYFgpgUsLqkkeXwe0slpzIhs%2B5294phB0cW%2FCiNRFoSj%2BLfbVNfigrh2zED9iD9eeNwQ94G%2FzZbV88afm%2F4C%2BBvuQOa9fvytwC%2BSsREaB3kL%2Bbtkax0x2jHqKpYhiSZGmn16pg6tUexFNte1pbl6%2FUo9q0S8W8tqIBHBIj9h0z4AT0YFaf4QpA%2FJaK94b27RSAE27BKE%2FCXQ0Ee%2Fv57Oqys1B%2FjDea7kp6aKpQ8xQc0f9qgCzGDQ6zLBT761oGWMo1PgQ93jtzFI3bZV%2BthDgOXKasO5MvaEL9Gut8OO%2BZT893POP7X1LcoiFq9ZWHCOs5NsohSB8F%2FiV7e25jWFSvJEbDs1P7TU5RBhWiWzPTn9AhE26YgXAehTnH0ZUzPC5&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220621T062404Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIA5FBUY2T3QVOTH2ES%2F20220621%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=5b1b70cb56798c4e093cd7df98eb7a27215b09b151e24489ad225d2b47edc9c1',
        },
        {
          photoName: 'photo04',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment04?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECQaDmFwLW5vcnRoZWFzdC0xIkgwRgIhAJBpOzIQ2B683yX7CUPwvPYcfhq9B3KEKYav4tv4S4GxAiEA4IoPr7ijqjjvRBELYcDi6MEs11j6zDR0r8MRGVP2GfYq%2BwIIPhABGgw5MDQyMDEyOTUwOTUiDCU%2But4kyf3C43R2uirYAt8SW8Nhzj9DC1UxulCClz9Kw9%2FXJAs2B2FFOpv3LQacAo9%2Fc%2BWXSj%2B1n7AioZqXhp9vcRFspU0a%2BjyOK9ys9WxMNerqyKRpzR0ckzor0Q0Rdot9c%2FbRBgmMLJR3cMcvIVN8z9UfaLEQvSSy4uSZ5PH3MMjWxs205ItR9qG%2FfLW1s9Csxo9%2BIr%2FJyptHa6hbV9X%2FTIpTEjX2tMQcPaxP4FRCpJnNLtNfKn87c2B7rEX80wzNY8Ehng%2Bf%2Fr%2Fg3Sxk8BxjMvXvSobuFdrA62rMqEbre3%2B3YNjQhaSXX9V%2BNABhlivrB9xTUGdK%2BLhPC6CyJwOM63mMotpfVKu00UuIy5jw57t5iK364%2BviphqFoPoEjDDq%2F%2Bf5g71rMuL1OpD5zGZW8p9OVisEIiX43NpPzk4TgWnuxF9fE7%2BXEAwUKlFbj6uDce7MbCBGGxuUQQhnFLQBzU%2BapvvmMNGVxZUGOrICOt6ieUGyOHG7dQZn4N9GcsMcYFgpgUsLqkkeXwe0slpzIhs%2B5294phB0cW%2FCiNRFoSj%2BLfbVNfigrh2zED9iD9eeNwQ94G%2FzZbV88afm%2F4C%2BBvuQOa9fvytwC%2BSsREaB3kL%2Bbtkax0x2jHqKpYhiSZGmn16pg6tUexFNte1pbl6%2FUo9q0S8W8tqIBHBIj9h0z4AT0YFaf4QpA%2FJaK94b27RSAE27BKE%2FCXQ0Ee%2Fv57Oqys1B%2FjDea7kp6aKpQ8xQc0f9qgCzGDQ6zLBT761oGWMo1PgQ93jtzFI3bZV%2BthDgOXKasO5MvaEL9Gut8OO%2BZT893POP7X1LcoiFq9ZWHCOs5NsohSB8F%2FiV7e25jWFSvJEbDs1P7TU5RBhWiWzPTn9AhE26YgXAehTnH0ZUzPC5&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220621T062421Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIA5FBUY2T3QVOTH2ES%2F20220621%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=c952307fe06d2ac5264b6cba4edff5d6cbcc0ec58d828634da029ec2f2eec198',
        },
        {
          photoName: 'photo05',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment05?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECQaDmFwLW5vcnRoZWFzdC0xIkgwRgIhAJBpOzIQ2B683yX7CUPwvPYcfhq9B3KEKYav4tv4S4GxAiEA4IoPr7ijqjjvRBELYcDi6MEs11j6zDR0r8MRGVP2GfYq%2BwIIPhABGgw5MDQyMDEyOTUwOTUiDCU%2But4kyf3C43R2uirYAt8SW8Nhzj9DC1UxulCClz9Kw9%2FXJAs2B2FFOpv3LQacAo9%2Fc%2BWXSj%2B1n7AioZqXhp9vcRFspU0a%2BjyOK9ys9WxMNerqyKRpzR0ckzor0Q0Rdot9c%2FbRBgmMLJR3cMcvIVN8z9UfaLEQvSSy4uSZ5PH3MMjWxs205ItR9qG%2FfLW1s9Csxo9%2BIr%2FJyptHa6hbV9X%2FTIpTEjX2tMQcPaxP4FRCpJnNLtNfKn87c2B7rEX80wzNY8Ehng%2Bf%2Fr%2Fg3Sxk8BxjMvXvSobuFdrA62rMqEbre3%2B3YNjQhaSXX9V%2BNABhlivrB9xTUGdK%2BLhPC6CyJwOM63mMotpfVKu00UuIy5jw57t5iK364%2BviphqFoPoEjDDq%2F%2Bf5g71rMuL1OpD5zGZW8p9OVisEIiX43NpPzk4TgWnuxF9fE7%2BXEAwUKlFbj6uDce7MbCBGGxuUQQhnFLQBzU%2BapvvmMNGVxZUGOrICOt6ieUGyOHG7dQZn4N9GcsMcYFgpgUsLqkkeXwe0slpzIhs%2B5294phB0cW%2FCiNRFoSj%2BLfbVNfigrh2zED9iD9eeNwQ94G%2FzZbV88afm%2F4C%2BBvuQOa9fvytwC%2BSsREaB3kL%2Bbtkax0x2jHqKpYhiSZGmn16pg6tUexFNte1pbl6%2FUo9q0S8W8tqIBHBIj9h0z4AT0YFaf4QpA%2FJaK94b27RSAE27BKE%2FCXQ0Ee%2Fv57Oqys1B%2FjDea7kp6aKpQ8xQc0f9qgCzGDQ6zLBT761oGWMo1PgQ93jtzFI3bZV%2BthDgOXKasO5MvaEL9Gut8OO%2BZT893POP7X1LcoiFq9ZWHCOs5NsohSB8F%2FiV7e25jWFSvJEbDs1P7TU5RBhWiWzPTn9AhE26YgXAehTnH0ZUzPC5&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220621T062443Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIA5FBUY2T3QVOTH2ES%2F20220621%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=6ff6128e9755a57e4b9e74bb5984f5354db446c912155ed4687dded5bfc1592c',
        },
      ],
      voltage: 1,
      outputPower: '3.2',
      evcMaker: 1,
      evcProductNumber: 'EVC設備の品番',
      accessoryEquipment: '付帯設備\n付帯設備\n付帯設備',
      unlockEnable: true,
      unlockNumber: '0000',
      evcExplanation: '補足説明',
      parkingPhoto: [
        {
          photoName: 'photo01',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=b6ad2d7c3401b4ce06c6411294843c976823e5ac3e8da5293ec6b438463ddf9c',
        },
        {
          photoName: 'photo02',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking02?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=1a628bf6eca2eedf44e18a152262703e69a0a7f40513242bd10fa77d03c3d55a',
        },
        {
          photoName: 'photo03',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking03?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=426cfdd7f43263182695826e14a3734ba29af135d1772e875cf76733cfea3fb0',
        },
        {
          photoName: 'photo04',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking04?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=a6df5dc745bd39b39a74d2901adac709df9beded67915857ba577d27973433d6',
        },
        {
          photoName: 'photo05',
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking05?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=2b93c4665f72a1616623f3398107780ceba6ab376a8020c2c4e2d70a002f7790',
        },
      ],
      carWidth: '2',
      carLength: '5.5',
      carHeight: '3',
      underCarHeight: '',
      tireWidth: '',
      carWeight: '',
      maxAvailableCarType: 200,
      parkingTypeForm: 1,
      parkingTypePavement: 1,
      parkingTypeRoof: 2,
      solarPanelFlag: 2,
      reEnergyFlag: 2,
      evcQrNumber: '設備QR番号',
      reservationFlag: 2,
      supportFlag: true,
      existCoupon: true,
      maxFeeInfomation: 2000,
      minFeeInfomation: 1000,
    },
  });
};
