import clsx from 'clsx';

const rows = {
  1: 'row-span-1',
  2: 'row-span-2',
  3: 'row-span-3',
  4: 'row-span-4',
  6: 'row-span-6',
  8: 'row-span-8',
  12: 'row-span-12',
};

const cols = {
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
  8: 'col-span-8',
  9: 'col-span-9',
  10: 'col-span-10',
  12: 'col-span-12',
  20: 'col-span-20',
  38: 'col-span-38',
};

type ColProps = {
  children: React.ReactNode;
  row?: keyof typeof rows;
  col?: keyof typeof cols;
};

export const Col = ({ children, row = 1, col = 1 }: ColProps) => {
  return <div className={clsx(rows[row], cols[col])}>{children}</div>;
};
