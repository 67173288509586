import clsx from 'clsx';
import { Control, Controller } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type ToggleCheckboxFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  name: string;
  control: Control<any>;
  itemLabel?: string;
};

export const ToggleCheckboxField = (props: ToggleCheckboxFieldProps) => {
  const { label, error, className, name, control, required, itemLabel } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FieldWrapper label={label} error={error} required={required}>
            <input
              type="checkbox"
              className={clsx('form-input float-left mr-2', className)}
              checked={field.value === '1' ? true : false}
              id={name}
              onChange={(e) => {
                field.onChange(e.target.checked ? '1' : '0');
              }}
            />
            {itemLabel && (
              <label className={clsx('h-6 leading-6')} htmlFor={name}>
                {itemLabel}
              </label>
            )}
          </FieldWrapper>
        );
      }}
    />
  );
};
