import clsx from 'clsx';
import { useCallback } from 'react';

import { useDisclosure } from '@/hooks/useDisclosure';

type ToggleButtonProps = {
  onClick: (isOpen: boolean) => void;
  leftName: string;
  rightName: string;
};

const falseStyle = '';

const trueStyle = 'bg-white disable cursor-default';

const buttonStyle = 'rounded-xl w-32 focus:outline-none transition';

export const ToggleButton = (props: ToggleButtonProps) => {
  const { onClick, leftName, rightName } = props;
  const { isOpen, toggle } = useDisclosure();

  const callback = useCallback(() => {
    toggle();
    onClick(isOpen);
  }, [isOpen, onClick, toggle]);

  return (
    <>
      <div className="flex bg-base-100 rounded-xl p-1 h-14">
        <button
          className={clsx(buttonStyle, isOpen ? falseStyle : trueStyle)}
          onClick={callback}
          disabled={!isOpen}
        >
          <span className="mx-2">{leftName}</span>
        </button>
        <button
          className={clsx(buttonStyle, isOpen ? trueStyle : falseStyle)}
          onClick={callback}
          disabled={isOpen}
        >
          <span className="mx-2">{rightName}</span>
        </button>
      </div>
    </>
  );
};
