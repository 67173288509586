import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type UserRefStatusApiQuery = {
  quitFlag: boolean;
  idCheckState: number;
  antisocialCheckFlag: number;
  uuidRegisteredFlag: boolean;
  coinbankRegState: number;
  userRole: number;
  userKind: boolean;
};

export const UserRefStatusApiErrType = {
  0: 'エラー無し',
  1: 'ユーザ無',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

type UserRefStatusApiErrTypeKey = keyof typeof UserRefStatusApiErrType;

export const useUserRefStatusApiQuery = <TData = UserRefStatusApiQuery>(
  variables: undefined,
  options?: UseQueryOptions<UserRefStatusApiQuery, ApiError<UserRefStatusApiErrTypeKey>, TData>
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useQuery<UserRefStatusApiQuery, ApiError<UserRefStatusApiErrTypeKey>, TData>(
    ['user_ref_status_api', variables],
    useFetchData<UserRefStatusApiQuery, ApiError<UserRefStatusApiErrTypeKey>, undefined>(
      'user_ref_status_api'
    ).bind(null, variables),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert('アクセスに失敗しました。再度ログインしてください。');
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
