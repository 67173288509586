import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

const sizes = {
  auto: 'flex-auto',
  sm: 'w-40',
  md: 'w-80',
  lg: 'w-120',
};

type Option = {
  label: React.ReactNode;
  value: string | number;
};

type RadioFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  className?: string;
  defaultValue?: string | number;
  registration: Partial<UseFormRegisterReturn>;
  readOnly?: boolean;
  size?: keyof typeof sizes;
  disabled?: boolean;
};

export const RadioField = (props: RadioFieldProps) => {
  const {
    label,
    options,
    registration,
    error,
    readOnly,
    required,
    size = 'auto',
    className,
    disabled,
  } = props;
  return (
    <FieldWrapper label={label} error={error} required={required}>
      <div className={clsx('flex w-full', className)}>
        {options.map(({ label, value }) => (
          <div key={value} className={clsx(sizes[size])}>
            <input
              type="radio"
              className={clsx('form-input float-left mr-2')}
              value={value}
              {...registration}
              id={`${registration.name}-${value}`}
              readOnly={readOnly}
              disabled={disabled}
            />
            <label className={clsx('h-6 leading-6')} htmlFor={`${registration.name}-${value}`}>
              {label}
            </label>
          </div>
        ))}
      </div>
    </FieldWrapper>
  );
};
