import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { ButtonGroup } from '@/components/Elements/Button/ButtonGroup';
import { Title } from '@/components/Elements/Title/Title';
import { Container } from '@/components/Layout/Grid';
import { queryClient } from '@/lib/react-query';

export const UserAddComplete = () => {
  const navigate = useNavigate();
  return (
    <>
      <Title className="text-center font-bold ml-0 mr-0" mx="mx-0">
        会員情報登録完了
      </Title>
      <img src="/assets/img/icon_done_fill_80.png" className="my-8 w-max mx-auto" alt="" />
      <img src="/assets/img/flow_05.png" className="w-max mx-auto" alt="" />
      <p className="text-center font-medium text-xl mt-10 text-main-700">登録が完了しました。</p>
      <Container px="px-64" py="py-10">
        <p>ご登録内容はマイページよりご確認いただけます。</p>
      </Container>
      <div className="mb-12 mt-6">
        <ButtonGroup align="center">
          <Button
            type="button"
            onClick={() => {
              queryClient.clear();
              navigate('/app/mypage');
            }}
          >
            はじめる
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
};
