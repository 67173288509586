import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type UserNoticeReadListRefApiQueryVariables = {
  lastSendDatetime: string;
  maxCount: number | null;
};

export type UserNoticeReadListRefApiQuery = {
  existsNextDataFlag: boolean;
  lastSendDatetime: string;
  listNum: number;
  listData: InfomationItem[];
};

export type InfomationItem = {
  notificationId: string;
  notificationType: number;
  sendDatetime: string;
  title: string;
  content: string;
};

export const UserNoticeReadListRefApiErrType = {
  0: 'エラー無し',
  1: '入力値不正',
  2: '該当ユーザ無',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

type UserNoticeReadListRefApiErrTypeKey = keyof typeof UserNoticeReadListRefApiErrType;

export const useUserNoticeReadListRefApiQuery = <TData = UserNoticeReadListRefApiQuery>(
  variables: UserNoticeReadListRefApiQueryVariables,
  options?: UseQueryOptions<
    UserNoticeReadListRefApiQuery,
    ApiError<UserNoticeReadListRefApiErrTypeKey>,
    TData
  >
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useQuery<
    UserNoticeReadListRefApiQuery,
    ApiError<UserNoticeReadListRefApiErrTypeKey>,
    TData
  >(
    ['user_notices_read_listref_api', variables],
    useFetchData<
      UserNoticeReadListRefApiQuery,
      ApiError<UserNoticeReadListRefApiErrTypeKey>,
      undefined
    >('user_notices_read_listref_api').bind(null, variables),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert('アクセスに失敗しました。再度ログインしてください。');
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
