import { useState } from 'react';

import { useUserNoticeReadListRefApiQuery } from '@/api/user_notices_read_listref_api/useUserNoticeReadListRefApiQuery';

import { useSingleton } from '../db/db';

export const useSyncNotices = () => {
  const db = useSingleton();

  // state 最終更新日時
  const [updateTimeStamp, setUpdateTimeStamp] = useState<Date>();

  // state lastSendDatetime
  const [lastSendDatetime, setLastSendDatetime] = useState('');

  // query お知らせ一覧取得API
  const { refetch } = useUserNoticeReadListRefApiQuery(
    { lastSendDatetime: lastSendDatetime, maxCount: 30 },
    {
      refetchInterval: 1000 * 60 * 5,
      refetchIntervalInBackground: true,
      enabled: db ? true : false,
      onSuccess: async (data) => {
        // 次データ検索フラグ
        let needNextFetch = true;

        try {
          // 取得データをlocalDBに格納
          for await (const notice of data.listData ?? []) {
            // localDBと突合
            const localNotice = await db?.notices.get(notice.notificationId);

            if (localNotice) {
              // 突合したら処理中断
              needNextFetch = false;
              break;
            }

            // データ加工
            const _notice = {
              notificationId: notice.notificationId,
              notificationType: notice.notificationType,
              sendDatetime: new Date(notice.sendDatetime),
              title: notice.title,
              content: notice.content,
              isRead: '0',
            };

            // データ追加
            await db?.notices.add(_notice);
          }

          if (data.existsNextDataFlag && data.lastSendDatetime && needNextFetch) {
            // 次検索へ
            setLastSendDatetime(data.lastSendDatetime);
          } else {
            // 最終検索時

            // 3か月前の1日
            const limitDate = new Date();
            limitDate.setMonth(limitDate.getMonth() - 3, 1);
            limitDate.setHours(0, 0, 0, 0);

            // 削除対象レコードのキーを取得
            const oldKeys = await db?.notices.where('sendDatetime').below(limitDate).primaryKeys();

            // 削除実行
            if (oldKeys && oldKeys.length > 0) {
              await db?.notices.bulkDelete(oldKeys);
            }

            // 最終更新日時セット
            setUpdateTimeStamp(new Date());
          }
        } catch (e) {
          console.log(e);
        }
      },
    }
  );

  return { refetch, updateTimeStamp };
};
