import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/rsv_fee_calc_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: 'エラー無し',
      },
    ],
    data: {
      fee: 10000,
      default_home_fee: 100,
    },
  });
};
