import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/user_ref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      nickName: 'ヤマダタロー',
      dispUserId: 'USER00000001',
      surname: '山田',
      name: '太郎',
      surnameRuby: 'ヤマダ',
      nameRuby: 'タロウ',
      postCode: '8120012',
      prefectures: '福岡県',
      municipalities: '福岡市博多区博多駅',
      address: '1-1',
      buildingName: '〇〇ビル201',
      birthday: '1980-01-01',
      corporateName: '株式会社〇〇',
      corporateNameRuby: 'カブシキガイシャ',
      userKind: true,
      uuid: 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX',
      userRole: 0,
    },
  });
};
