import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType } from 'react';
// import { Navigate, Outlet } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { Wrapper } from '@/features/callback';
import { NoticesProvider } from '@/features/info';
import { isMocked } from '@/lib/isMocked';
import { lazyImport } from '@/utils/lazyImport';

// import { Spinner } from '@/components/Elements';

const { EvcRoutes } = lazyImport(() => import('@/features/evc'), 'EvcRoutes');
const { MypageRoutes } = lazyImport(() => import('@/features/mypage'), 'MypageRoutes');
const { ReserveChargeRoutes } = lazyImport(
  () => import('@/features/reserve_charge'),
  'ReserveChargeRoutes'
);
const { RsvRoutes } = lazyImport(() => import('@/features/rsv'), 'RsvRoutes');
const { UserRoutes } = lazyImport(() => import('@/features/user'), 'UserRoutes');
const { InfoRoutes } = lazyImport(() => import('@/features/info'), 'InfoRoutes');
const { ManagerCompleteRoutes } = lazyImport(
  () => import('@/features/evc'),
  'ManagerCompleteRoutes'
);
const { SpecificCompleteRoutes } = lazyImport(
  () => import('@/features/evc'),
  'SpecificCompleteRoutes'
);
const { EarningsRoutes } = lazyImport(() => import('@/features/earnings'), 'EarningsRoutes');
const { DashboardRoutes } = lazyImport(() => import('@/features/dashboard'), 'DashboardRoutes');

const App = () => {
  return (
    <Wrapper>
      <NoticesProvider>
        <Outlet />
      </NoticesProvider>
    </Wrapper>
  );
};

const Protected = ({ component }: { component: ComponentType }) => {
  const Component = withAuthenticationRequired(component, {});
  return <Component />;
};

export const PROTECTED_ROUTE_BASE = '/app';

export const protectedRoutes = [
  {
    path: PROTECTED_ROUTE_BASE,
    element: isMocked() ? <App /> : <Protected component={App} />,
    children: [
      { path: '/evc/*', element: <EvcRoutes /> },
      { path: '/mypage/*', element: <MypageRoutes /> },
      { path: '/reserve_charge/*', element: <ReserveChargeRoutes /> },
      { path: '/rsv/*', element: <RsvRoutes /> },
      { path: '/user/*', element: <UserRoutes /> },
      { path: '/info/*', element: <InfoRoutes /> },
      { path: '/earnings/*', element: <EarningsRoutes /> },
      { path: '/dashboard/*', element: <DashboardRoutes /> },
    ],
  },
  {
    path: '/companyaccept',
    element: <Protected component={App} />,
    children: [{ path: '*', element: <ManagerCompleteRoutes /> }],
  },
  {
    path: '/useraccept',
    element: <Protected component={App} />,
    children: [{ path: '*', element: <SpecificCompleteRoutes /> }],
  },
];
