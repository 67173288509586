import { isMocked } from '@/lib/isMocked';

import { UserInfo } from './UserInfo';
import { UserInfoMock } from './UserInfoMock';
import { UserInfoOnlyLogout } from './UserInfoOnlyLogout';

export const ProtectedHeader = ({ isOnlyLogout }: { isOnlyLogout?: boolean }) => {
  return (
    <>
      <div id="header">
        <div id="info">
          {isMocked() ? <UserInfoMock /> : isOnlyLogout ? <UserInfoOnlyLogout /> : <UserInfo />}
        </div>
      </div>
    </>
  );
};
