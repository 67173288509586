import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';
import { UnitLabel } from './UnitLabel';

const sizes = {
  auto: 'form-select flex-auto',
  full: 'form-select w-full',
  sm: 'form-select w-40',
  md: 'form-select w-80',
  lg: 'form-select w-120',
};

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
};

type SelectFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  className?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
  optional?: boolean;
  readOnly?: boolean;
  size?: keyof typeof sizes;
  unit?: string;
  disabled?: boolean;
  innerRef?: React.MutableRefObject<HTMLSelectElement | null> | undefined;
};

const placeholderKey = '';
const placeholderClass = 'text-base-300';

export const SelectField = (props: SelectFieldProps) => {
  const {
    label,
    options,
    error,
    registration,
    placeholder,
    optional,
    required,
    size = 'full',
    className,
    unit,
    disabled,
    innerRef,
    description,
  } = props;
  return (
    <FieldWrapper label={label} error={error} required={required} description={description}>
      {unit ? (
        <UnitLabel unit={unit}>
          <select
            disabled={disabled}
            className={clsx(sizes[size], className)}
            ref={(select) => {
              registration.ref && registration.ref(select);
              if (innerRef !== undefined) innerRef.current = select;
              placeholder && select?.value === placeholderKey
                ? select?.classList.add(placeholderClass)
                : select?.classList.remove(placeholderClass);
            }}
            name={registration.name}
            onChange={(evt) => {
              placeholder && evt.target.value === placeholderKey
                ? evt.target.classList.add(placeholderClass)
                : evt.target.classList.remove(placeholderClass);
              registration.onChange && registration.onChange(evt);
            }}
            onBlur={(evt) => {
              placeholder && evt.target.value === placeholderKey
                ? evt.target.classList.add(placeholderClass)
                : evt.target.classList.remove(placeholderClass);
              registration.onBlur && registration.onBlur(evt);
            }}
          >
            {placeholder && (
              <option className="hidden" disabled value={placeholderKey} selected>
                {placeholder}
              </option>
            )}
            {optional && <option className="text-black" key="-" value="" />}
            {options.map(({ label, value }) => (
              <option className="text-black" key={label?.toString()} value={value}>
                {label}
              </option>
            ))}
          </select>
        </UnitLabel>
      ) : (
        <select
          disabled={disabled}
          className={clsx(sizes[size], className)}
          ref={(select) => {
            registration.ref && registration.ref(select);
            if (innerRef !== undefined) innerRef.current = select;
            placeholder && select?.value === placeholderKey
              ? select?.classList.add(placeholderClass)
              : select?.classList.remove(placeholderClass);
          }}
          name={registration.name}
          onChange={(evt) => {
            placeholder && evt.target.value === placeholderKey
              ? evt.target.classList.add(placeholderClass)
              : evt.target.classList.remove(placeholderClass);
            registration.onChange && registration.onChange(evt);
          }}
          onBlur={(evt) => {
            placeholder && evt.target.value === placeholderKey
              ? evt.target.classList.add(placeholderClass)
              : evt.target.classList.remove(placeholderClass);
            registration.onBlur && registration.onBlur(evt);
          }}
        >
          {placeholder && (
            <option className="hidden" disabled value={placeholderKey} selected>
              {placeholder}
            </option>
          )}
          {optional && <option className="text-black" key="-" value="" />}
          {options.map(({ label, value }) => (
            <option className="text-black" key={label?.toString()} value={value}>
              {label}
            </option>
          ))}
        </select>
      )}
    </FieldWrapper>
  );
};
