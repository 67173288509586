import clsx from 'clsx';

const cols = {
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  6: 'grid-cols-6',
  8: 'grid-cols-8',
  12: 'grid-cols-12',
  24: 'grid-cols-24',
  48: 'grid-cols-48',
};

type RowProps = {
  children: React.ReactNode;
  col?: keyof typeof cols;
  mb?: string;
  mt?: string;
  px?: string;
  gap?: string;
  className?: string;
};

export const Row = ({
  children,
  col = 4,
  mb = 'mb-8',
  mt = 'mt-0',
  px = 'px-0',
  gap = 'gap-5',
  className,
}: RowProps) => {
  return <div className={clsx('grid', cols[col], gap, mb, mt, px, className)}>{children}</div>;
};
