import { useAuth0 } from '@auth0/auth0-react';

import { Button, SimpleCard } from '@/components/Elements';
import { ButtonGroup } from '@/components/Elements/Button/ButtonGroup';
import { Title } from '@/components/Elements/Title/Title';
import { Item } from '@/components/Layout/Flex';
import { Container } from '@/components/Layout/Grid';
import { PublicLayout } from '@/components/Layout/PublicLayout';
import { useLogout } from '@/hooks/useLogout';

export const UserEmailConfirm = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { logout } = useLogout();
  return (
    <>
      <PublicLayout>
        <SimpleCard>
          <Title className="text-center font-bold ml-0 mr-0" mx="mx-0">
            メールアドレス確認
          </Title>
          <Button
            variant={'borderless'}
            size={'sm'}
            onClick={() => logout({ returnTo: window.location.origin + '/logout_callback/' })}
          >
            <p className="underline">＜戻る</p>
          </Button>
          <Container px="px-64" py="py-10">
            <p>
              メールアドレス確認のためにご登録のメールアドレスにメールを送信しました。リンクから承認したあと、「次へ」を押してください。
            </p>
          </Container>
          <div className="mb-12 mt-6">
            <ButtonGroup align="center">
              <Button
                type="button"
                onClick={async () => {
                  console.log(isAuthenticated);
                  loginWithRedirect();
                }}
              >
                次へ
              </Button>
            </ButtonGroup>
          </div>
          <Item className="mt-8 mb-8 text-center">
            <a
              href="https://support.charger-share.everiwa.jp/hc/ja"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              届かない場合はこちら
            </a>
          </Item>
        </SimpleCard>
      </PublicLayout>
    </>
  );
};
