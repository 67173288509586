import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { useLogout } from '@/hooks/useLogout';

import { useApiErrorAlert } from './useApiErrorAlert';

export const useApiStatusErrorHandler = () => {
  const { alert } = useApiErrorAlert();

  const { logout } = useLogout();

  const errorResolve = useCallback(
    (error: AxiosError) => {
      if (!error.response) {
        alert('ネットワークエラーが発生しました。\n通信環境をご確認ください。');
        return;
      }

      switch (error.response?.status) {
        case 500:
        case 501:
        case 504:
        case 429:
          alert(
            `問題が発生しました。(S${error.response.status}E)\nしばらく経ってからやり直してください。`
          );
          break;
        case 503:
          alert(`サービス利用不可です。${error.response?.data?.Message ?? ''}`);
          break;
        case 400:
        case 404:
        case 405:
          alert(
            `問題が発生しました。（S${error.response.status}）\n問い合わせフォームよりご連絡ください。`
          );
          break;
        case 403:
          if (
            error.response.data?.message ===
            'User is not authorized to access this resource with an explicit deny'
          ) {
            logout({
              returnTo: window.location.origin + '/logout_callback/',
            });
          } else {
            alert(
              `問題が発生しました。（S${error.response.status}）\n問い合わせフォームよりご連絡ください。`
            );
          }
          break;
        case 401:
          logout({
            returnTo: window.location.origin + '/logout_callback/',
          });
          break;
        case 302:
          break;
        default:
          alert(
            `問題が発生しました。（S${
              error.response?.status ?? ''
            }E）\n問い合わせフォームよりご連絡ください。`
          );
          break;
      }
    },
    [alert, logout]
  );

  return { errorResolve };
};
