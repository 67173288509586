import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

import { queryClient } from '@/lib/react-query';

export const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();

  const logout = useCallback(
    (options: LogoutOptions) => {
      queryClient.clear();
      auth0Logout(options);
    },
    [auth0Logout]
  );

  return { logout };
};
