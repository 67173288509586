import { useMutation, UseMutationOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type UserTermsPfAgreedUpdateApiMutationVariables = {
  version: string;
};

export const UserTermsPfAgreedUpdateApiErrType = {
  0: 'エラー無し',
  1: '該当ユーザ無し',
  2: 'バージョン不正',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

type UserTermsPfAgreedUpdateApiErrTypeKey = keyof typeof UserTermsPfAgreedUpdateApiErrType;

export const useUserTermsPfAgreedUpdateApiMutation = <TContext = unknown>(
  options?: UseMutationOptions<
    undefined,
    ApiError<UserTermsPfAgreedUpdateApiErrTypeKey>,
    UserTermsPfAgreedUpdateApiMutationVariables,
    TContext
  >
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useMutation<
    undefined,
    ApiError<UserTermsPfAgreedUpdateApiErrTypeKey>,
    UserTermsPfAgreedUpdateApiMutationVariables,
    TContext
  >(
    'user_terms_pf_agreed_update_api',
    useFetchData<
      undefined,
      ApiError<UserTermsPfAgreedUpdateApiErrTypeKey>,
      UserTermsPfAgreedUpdateApiMutationVariables
    >('user_terms_pf_agreed_update_api'),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert('アクセスに失敗しました。再度ログインしてください。');
              break;
            case 2:
              alert(`存在しない利用規約バージョンです。`);
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
