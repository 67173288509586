import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/user_mypage_ref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: 'エラー無し',
      },
    ],
    data: {
      nickName: 'mori',
      dispUserId: 'USER00000001',
      coinBalance: '10000',
      userPhotoGetUrl: 'https://test-evc-photo-bucket.xxxxxxxx',
      mailAddress: 'yamada_.-@yahoo.co.jp',
      phoneNumber: '08027994774',
    },
  });
};
