import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError } from 'axios';

import { API_URL, AUTH0_AUDIENCE } from '@/config';

import { ApiError } from './ApiError';
import { APIResponse } from './Response';
import { useApiStatusErrorHandler } from './useApiStatusErrorHandler';

const client = axios.create({
  baseURL: API_URL,
});

export const useFetchData = <TData, TError, TVariables>(query: string): (() => Promise<any>) => {
  const { getAccessTokenSilently } = useAuth0();
  const { errorResolve } = useApiStatusErrorHandler();

  return async (variables?: TVariables) => {
    return new Promise((resolve, reject) => {
      getAccessTokenSilently({
        scope: 'read:current_user',
        audience: AUTH0_AUDIENCE,
        detailedResponse: true,
      })
        .then((accessToken) => {
          client
            .post<APIResponse<TData, any>>(
              query,
              {
                ...variables,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken.access_token}`,
                },
              }
            )
            .then((res) => {
              if (res.data.result === 0) {
                resolve(res.data.data);
              } else {
                const err = new ApiError<TError>({
                  errCode: res.data.err[0].errCode,
                  errMsg: res.data.err[0].errMsg,
                });
                reject(err);
              }
            })
            .catch((e: AxiosError) => {
              errorResolve(e);
              const err = { errCode: -99, errMsg: '' };
              reject(err);
            });
        })
        .catch((error) => {
          if (error.error_description === 'Please verify your email before logging in.') {
            // メールアドレス確認ページ
            window.location.href = '/user/email';
          } else if (error.error_description === 'Login required') {
            reject(error);
          } else {
            reject(error);
          }
        });
    });
  };
};
