import { LocaleObject } from 'yup/lib/locale';

import {
  ARR_MAX,
  ARR_MIN,
  DATE_MAX,
  DATE_MIN,
  DEFAULT,
  EMAIL,
  formatMessage,
  INTEGER,
  LENGTH,
  LESS_THAN,
  LOWERCASE,
  MATCHES,
  MORE_THAN,
  NEGATIVE,
  NOTTYPE,
  NO_UNKNOWN,
  NUM_MAX,
  NUM_MIN,
  POSITIVE,
  REQUIRED,
  STR_MAX,
  STR_MIN,
  TRIM,
  UPPERCASE,
  URL,
} from './messages';

export const formErrorMessage: LocaleObject = {
  mixed: {
    default: DEFAULT,
    required: REQUIRED,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    // oneOf: ({ values }) => `次の値のいずれかでなければなりません: ${values}`,
    oneOf: REQUIRED,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    notOneOf: ({ values }) => `次の値のいずれかであってはなりません: ${values}`,
    notType: NOTTYPE,
  },
  string: {
    length: ({ length }) => formatMessage(LENGTH, length),
    min: ({ min }) => formatMessage(STR_MIN, min),
    max: ({ max }) => formatMessage(STR_MAX, max),
    matches: MATCHES,
    email: EMAIL,
    url: URL,
    trim: TRIM,
    lowercase: LOWERCASE,
    uppercase: UPPERCASE,
  },
  number: {
    min: ({ min }) => formatMessage(NUM_MIN, min),
    max: ({ max }) => formatMessage(NUM_MAX, max),
    lessThan: ({ less }) => formatMessage(LESS_THAN, less),
    moreThan: ({ more }) => formatMessage(MORE_THAN, more),
    positive: POSITIVE,
    negative: NEGATIVE,
    integer: INTEGER,
  },
  date: {
    min: ({ min }) => formatMessage(DATE_MIN, min),
    max: ({ max }) => formatMessage(DATE_MAX, max),
  },
  object: {
    noUnknown: NO_UNKNOWN,
  },
  array: {
    min: ({ min }) => formatMessage(ARR_MIN, min),
    max: ({ max }) => formatMessage(ARR_MAX, max),
    // min: '入力を確認してください',
    // max: '入力を確認してください',
  },
};
