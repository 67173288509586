import { useCallback } from 'react';

import { useLogout } from '@/hooks/useLogout';

import { ApiError } from './ApiError';
import { useApiErrorAlert } from './useApiErrorAlert';

export const useApiErrorHandler = () => {
  const { alert } = useApiErrorAlert();
  const { logout } = useLogout();

  const errorResolve = useCallback(
    (error: ApiError): boolean => {
      // API共通エラーハンドリング
      switch (error.errCode) {
        case 50:
          return true;
        case 51:
          alert(`問題が発生しました。（S200E051)\n問い合わせフォームよりご連絡ください。`);
          return true;
        case 100:
        case 200:
        case 999:
          alert(
            `問題が発生しました。(S200E${error.errCode})\nしばらく経ってからやり直してください。`
          );
          return true;
        case 300:
          alert(
            `問題が発生しました。(S200H300 ${
              error.message ?? ''
            })\n問い合わせフォームよりご連絡ください。`
          );
          return true;
        case 400:
          logout({
            returnTo: window.location.origin + '/logout_callback/',
          });
          return true;
        case -99:
          return true;
        default:
          // alert(`問題が発生しました。(S200E${error.code})\n問い合わせフォームよりご連絡ください。`);
          return false;
      }
    },
    [alert, logout]
  );

  return { errorResolve };
};
