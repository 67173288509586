import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type UserPhotoUrlRefApiQuery = {
  userPhotoPutUrl: string;
  userPhotoDeleteUrl: string;
};

export const UserPhotoUrlRefApiErrType = {
  0: 'エラー無し',
  1: '該当ユーザ無',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

type UserPhotoUrlRefApiErrTypeKey = keyof typeof UserPhotoUrlRefApiErrType;

export const useUserPhotoUrlRefApiQuery = <TData = UserPhotoUrlRefApiQuery>(
  variables: undefined,
  options?: UseQueryOptions<UserPhotoUrlRefApiQuery, ApiError<UserPhotoUrlRefApiErrTypeKey>, TData>
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useQuery<UserPhotoUrlRefApiQuery, ApiError<UserPhotoUrlRefApiErrTypeKey>, TData>(
    ['user_photo_url_ref_api', variables],
    useFetchData<UserPhotoUrlRefApiQuery, ApiError<UserPhotoUrlRefApiErrTypeKey>, undefined>(
      'user_photo_url_ref_api'
    ).bind(null, variables),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert('アクセスに失敗しました。再度ログインしてください。');
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
