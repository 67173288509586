import { useCallback } from 'react';

import { useConfirm } from '@/components/Confirm';

const TITLE = 'エラーが発生しました';

export const useApiErrorAlert = () => {
  const { confirm } = useConfirm();

  const alert = useCallback(
    (msg: string) => {
      confirm({ title: TITLE, message: msg, type: 'alert' });
    },
    [confirm]
  );

  return { alert };
};
