import { useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const route = [...protectedRoutes, ...publicRoutes];
  const element = useRoutes(route);

  return <div className=" bg-body-background">{element}</div>;
};
