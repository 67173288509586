import clsx from 'clsx';

const aligns = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};

type ButtonGroupProps = {
  children: React.ReactNode;
  align?: keyof typeof aligns;
};

export const ButtonGroup = ({ children, align = 'left' }: ButtonGroupProps) => {
  return <div className={clsx('flex gap-2 w-full', aligns[align])}>{children}</div>;
};
