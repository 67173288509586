import { FC } from 'react';

import { UserAdd as UserAddCom } from '../components/UserAdd';

export const UserAdd: FC = () => {
  return (
    <>
      <UserAddCom />
    </>
  );
};
