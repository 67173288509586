import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/evc_detail_reserve_ref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      monday: [
        {
          startTime: '10:00',
          endTime: '12:00',
        },
        {
          startTime: '20:00',
          endTime: '22:00',
        },
      ],
      tuesday: [
        {
          startTime: '11:00',
          endTime: '11:30',
        },
      ],
      wednesday: [],
      thursday: [
        {
          startTime: '11:00',
          endTime: '11:30',
        },
      ],
      friday: [
        {
          startTime: '23:00',
          endTime: '23:15',
        },
        {
          startTime: '23:30',
          endTime: '24:00',
        },
      ],
      saturday: [],
      sunday: [
        {
          startTime: '10:00',
          endTime: '14:00',
        },
        {
          startTime: '17:00',
          endTime: '21:00',
        },
      ],
      holiday: [
        {
          startTime: '09:00',
          endTime: '21:00',
        },
      ],
      targetday: [
        {
          targetDate: '2022-07-29',
          startTime: '10:00',
          endTime: '14:00',
        },
        {
          targetDate: '2022-07-29',
          startTime: '17:00',
          endTime: '21:00',
        },
        {
          targetDate: '2022-08-01',
          startTime: '09:30',
          endTime: '12:30',
        },
        {
          targetDate: '2022-08-13',
          startTime: '15:00',
          endTime: '18:00',
        },
      ],
    },
  });
};
