import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/user_token_set_api').reply(200, {
    result: 1,
    err: [
      {
        errCode: 1,
        errMsg: 'このメールアドレスを持つユーザー情報が見つかりません',
      },
    ],
  });
};
