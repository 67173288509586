export class ApiError<TError = number> extends Error {
  errCode: TError;
  errMsg: string;
  constructor(e: { errCode: TError; errMsg: string }) {
    super(e.errMsg);
    this.name = new.target.name;
    this.errCode = e.errCode;
    this.errMsg = e.errMsg;
  }
}
