import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { useSingleton, Notice } from '../db/db';

import { useSyncNotices } from './useSyncNotices';

type NoticesContextProps = {
  unreadCount: number;
  refetch: () => void;
  read: () => Promise<Notice[] | undefined>;
  update: (notificationId: string) => void;
  updateTimeStamp: Date | undefined;
};

const NoticeContext = createContext<NoticesContextProps | undefined>(undefined);

type NoticesProviderProps = {
  children: ReactNode;
};

export const NoticesProvider = (props: NoticesProviderProps) => {
  const db = useSingleton();

  // hook お知らせ取得apiを叩いて、indexedDBに保存
  const { refetch, updateTimeStamp } = useSyncNotices();

  // state 未読件数
  const [unreadCount, setUnreadCount] = useState(0);

  // callback indexedDBより未読のお知らせ件数を取得
  const updateUnreadCount = useCallback(() => {
    db?.notices
      .where('isRead')
      .equals('0')
      .count()
      .then((count) => setUnreadCount(count));
  }, [db]);

  // effect api突合終了後に未読件数を取得し、stateにset
  useEffect(() => {
    updateUnreadCount();
  }, [updateUnreadCount, updateTimeStamp]);

  // callback indexedDBよりお知らせを全件取得
  const read = useCallback(async () => {
    const notices = await db?.notices.reverse().sortBy('sendDatetime');
    return notices;
  }, [db]);

  // callback お知らせIDを受け取り、indexedDBの既読フラグを立てる
  const update = useCallback(
    (notificationId: string) => {
      db?.notices.update(notificationId, { isRead: '1' }).then(() => {
        updateUnreadCount();
      });
    },
    [db, updateUnreadCount]
  );

  return (
    <NoticeContext.Provider
      value={{ unreadCount, refetch, read, update, updateTimeStamp }}
      {...props}
    >
      {props.children}
    </NoticeContext.Provider>
  );
};

export const useNotices = () => {
  const context = useContext(NoticeContext);
  if (context == undefined) {
    throw new Error('useNotices must be used within a UserProvider');
  }
  return context;
};
