export const UserInfoMock = () => {
  return (
    <div id="user_info">
      <div id="user_name">
        モック
        <img src="/assets/img/icon_Arrow_downSmall.png" alt="" />
      </div>
      <div id="user_icon">
        <img src="/assets/img/icon_user.png" width="34" height="34" alt="ユーザーアイコン" />
      </div>
    </div>
  );
};
