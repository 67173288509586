import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserRefApiQueryChk } from '@/api/user_ref_api/useUserRefApiQueryChk';
import { Button, SimpleCard } from '@/components/Elements';
import { ButtonGroup } from '@/components/Elements/Button/ButtonGroup';
import { Title } from '@/components/Elements/Title/Title';
import { Container } from '@/components/Layout/Grid';
import { PublicLayout } from '@/components/Layout/PublicLayout';
import { queryClient } from '@/lib/react-query';

export const UserAccountComplete = () => {
  const navigate = useNavigate();

  useEffect(() => {
    queryClient.clear();
  }, []);

  const { data: userData } = useUserRefApiQueryChk(undefined, { suspense: true });
  if (userData) {
    navigate(`/app/mypage`);
  }

  return (
    <>
      <PublicLayout>
        <SimpleCard>
          <Title className="text-center font-bold ml-0 mr-0" mx="mx-0">
            メールアドレス承認完了
          </Title>
          <img src="/assets/img/icon_done_fill_80.png" className="my-8 w-max mx-auto" alt="" />
          <p className="text-center font-medium text-xl mt-10 text-main-700">
            メールアドレスの認証が完了しました。
          </p>
          <Container px="px-64" py="py-10">
            <p>続いて会員情報の入力をしてください</p>
          </Container>
          <div className="mb-12 mt-0">
            <ButtonGroup align="center">
              <Button
                type="button"
                onClick={() => {
                  navigate('/app/user/add');
                }}
              >
                会員情報を入力
              </Button>
            </ButtonGroup>
          </div>
        </SimpleCard>
      </PublicLayout>
    </>
  );
};
