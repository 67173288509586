import clsx from 'clsx';

type ContainerProps = {
  children: React.ReactNode;
  px?: string;
  py?: string;
};

export const Container = ({ children, px = 'px-6', py = 'py-5' }: ContainerProps) => {
  return <div className={clsx('w-full', px, py)}>{children}</div>;
};
