import {
  createContext,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Button } from '@/components/Elements';

import { useDialog } from '../Elements/Dialog';

type ConfirmContextProps = { confirm: (msg: ConfirmMessage) => void; isOpen: boolean };

const ConfirmContext = createContext<ConfirmContextProps | undefined>(undefined);

const types = {
  alert: 'alert',
  confirm: 'confirm',
  info: 'info',
};

type ConfirmMessage = {
  title: string;
  message: string;
  type?: keyof typeof types;
};

type ConfirmProviderProps = {
  children: ReactNode;
};
export const ConfirmProvider = (props: ConfirmProviderProps) => {
  const [message, setMessage] = useState<ConfirmMessage>();

  const cancelButtonRef = useRef(null);

  const { Dialog, close, open, isOpen } = useDialog();

  useEffect(() => {
    window.addEventListener('popstate', close);

    return () => {
      window.removeEventListener('popstate', close);
    };
  }, [close]);

  const confirm = useCallback(
    (msg: ConfirmMessage) => {
      setMessage(msg);
      open();
    },
    [open]
  );

  const title = {
    alert: <div className="text-accent-800">{message?.title}</div>,
    confirm: <div>{message?.title}</div>,
    info: <div>{message?.title}</div>,
  };

  return (
    <ConfirmContext.Provider value={{ confirm, isOpen }} {...props}>
      {props.children}
      <Dialog
        title={title[message?.type ?? 'info']}
        initialFocus={cancelButtonRef}
        closeable={false}
      >
        <div className="my-8 flex flex-col items-center">
          {(() => {
            if (message?.message) {
              const split = message.message.split('\n');
              if (split.length > 1) {
                return split.map((x, i) => {
                  return (
                    <Fragment key={i}>
                      {x}
                      <br />
                    </Fragment>
                  );
                });
              }
            }
            return message?.message;
          })()}
        </div>
        <div className="flex justify-center my-4">
          <Button type="button" onClick={close} ref={cancelButtonRef}>
            閉じる
          </Button>
        </div>
      </Dialog>
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const context = useContext(ConfirmContext);
  if (context === undefined) {
    throw new Error('useConfirm must be used within a UserProvider');
  }

  return context;
};
