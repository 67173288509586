import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type UserGeocodingApiQueryVariables = {
  postalCode: string;
};

export type UserGeocodingApiQuery = {
  latlng: string;
  address: Address;
};

export type Address = {
  formatted_address: string;
};

export const UserGeocodingApiErrType = {
  0: 'エラー無し',
  1: '該当ユーザ無',
  2: '地理情報取得エラー',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

type UserGeocodingApiErrTypeKey = keyof typeof UserGeocodingApiErrType;

export const useUserGeocodingApiQuery = <TData = UserGeocodingApiQuery>(
  variables: UserGeocodingApiQueryVariables,
  options?: UseQueryOptions<UserGeocodingApiQuery, ApiError<UserGeocodingApiErrTypeKey>, TData>
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useQuery<UserGeocodingApiQuery, ApiError<UserGeocodingApiErrTypeKey>, TData>(
    ['user_geocoding_api', variables],
    useFetchData<
      UserGeocodingApiQuery,
      ApiError<UserGeocodingApiErrTypeKey>,
      UserGeocodingApiQueryVariables
    >('user_geocoding_api').bind(null, variables),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert('アクセスに失敗しました。再度ログインしてください。');
              break;
            case 2:
              alert('住所の取得に失敗しました');
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
