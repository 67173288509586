import { Wrapper, Status } from '@googlemaps/react-wrapper';
import clsx from 'clsx';
import _ from 'lodash';
import { useRef, useState, useEffect, Children, cloneElement, isValidElement } from 'react';
import { Control, Controller, ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import {
  MapApikeyGetApiOsType,
  useMapApikeyGetApiQuery,
} from '@/api/map_apikey_get_api/useMapApikeyGetApiQuery';

import { FieldWrapper } from './FieldWrapper';

interface MapProps extends google.maps.MapOptions {
  className: string;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  latlng: Latlng;
  field?: ControllerRenderProps<any, any>;
}

interface MarkerProps extends google.maps.MarkerOptions {
  onDragEnd?: (e: google.maps.MapMouseEvent) => void;
}

type MapFieldBaseProps = {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
};

type MapFieldProps = {
  name: string;
  label: string;
  control: Control<any>;
  required?: boolean;
};

type MapReadOnlyProps = {
  label: string;
  lat: number | undefined;
  lng: number | undefined;
};

type Latlng = {
  lat: number;
  lng: number;
};

export const initLatlng: Latlng = { lat: 35.658584, lng: 139.7454316 };

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

export const Map: React.FC<MapProps> = ({
  children,
  className,
  onClick,
  onIdle,
  latlng,
  field,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center: { lat: latlng.lat, lng: latlng.lng },
          zoom: 17,
        })
      );
      if (field) field.onChange({ lat: latlng.lat, lng: latlng.lng });
    }
  }, [ref, map, latlng, field]);

  useEffect(() => {
    if (map) {
      map.setCenter({ lat: latlng.lat, lng: latlng.lng });
    }
  }, [map, latlng]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div className={className} ref={ref} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

export const MapFieldBase = ({ field }: MapFieldBaseProps) => {
  const [latlng, setLatlng] = useState<Latlng>();

  useEffect(() => {
    if (field.value && !_.isEqual(field.value, initLatlng)) {
      setLatlng(field.value);
    } else {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setLatlng({ lat: pos.coords.latitude, lng: pos.coords.longitude });
        },
        () => {
          setLatlng(initLatlng);
        }
      );
    }
  }, [field.value]);

  // GOOGLE_MAP_API_KEY取得
  const { data } = useMapApikeyGetApiQuery({ osType: MapApikeyGetApiOsType.PC });

  return (
    <div className="flex h-100">
      {data?.apiKey && (
        <Wrapper apiKey={data.apiKey} render={render}>
          {latlng && (
            <Map
              className="w-full h-full"
              latlng={latlng}
              onIdle={() => {}}
              onClick={(map) => {
                if (map.latLng) {
                  const newLatLnd = { lat: map.latLng.lat(), lng: map.latLng.lng() };
                  field.onChange(newLatLnd);
                }
              }}
              field={field}
              scrollwheel={false}
            >
              <Marker
                position={latlng}
                draggable
                onDragEnd={(map) => {
                  if (map.latLng) {
                    const newLatLnd = { lat: map.latLng.lat(), lng: map.latLng.lng() };
                    field.onChange(newLatLnd);
                  }
                }}
              />
            </Map>
          )}
        </Wrapper>
      )}
    </div>
  );
};

export const MapField = ({ name, label, control, required }: MapFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <FieldWrapper label={label} error={fieldState.error} required={required}>
            <p>設置場所をマップ上で設定してください。設置場所をタップすると設定できます。</p>
            <MapFieldBase field={field} fieldState={fieldState} />
          </FieldWrapper>
        );
      }}
    />
  );
};

export const MapFieldReadOnly = ({ label, lat, lng }: MapReadOnlyProps) => {
  const [latlng, setLatlng] = useState<Latlng>();

  useEffect(() => {
    if (lat && lng) {
      setLatlng({ lat: lat, lng: lng });
    } else {
      setLatlng(initLatlng);
    }
  }, [lat, lng]);

  // GOOGLE_MAP_API_KEY取得
  const { data } = useMapApikeyGetApiQuery({ osType: MapApikeyGetApiOsType.PC });

  return (
    <>
      {label && (
        <label className={clsx('block mt-4 mb-2 text-sm text-txblack-alpha-600')}>{label}</label>
      )}
      <div className="flex h-100">
        {data?.apiKey && (
          <Wrapper apiKey={data.apiKey} render={render}>
            <Map
              className="w-full h-full"
              latlng={latlng ?? initLatlng}
              onIdle={() => {}}
              scrollwheel={false}
            >
              <Marker position={latlng} draggable={false} />
            </Map>
          </Wrapper>
        )}
      </div>
    </>
  );
};

const Marker: React.FC<MarkerProps> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  useEffect(() => {
    if (marker) {
      if (options.onDragEnd) {
        marker.addListener('dragend', options.onDragEnd);
      }
    }
  }, [marker, options.onDragEnd]);

  return null;
};
