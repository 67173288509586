import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { focusManager } from 'react-query';

import {
  UserHctermsUpdateCheckApiQuery,
  useUserHctermsUpdateCheckApiQuery,
} from '@/api/user_hcterms_update_check_api/useUserHctermsUpdateCheckApiQuery';
import { useUserRefStatusApiQuery } from '@/api/user_ref_status_api/useUserRefStatusApiQuery';
import {
  UserTermsPfComfirmApiQuery,
  useUserTermsPfComfirmApiQuery,
} from '@/api/user_terms_pf_confirm_api/useUserTermsPfComfirmApiQuery';
import { AUTH0_AUDIENCE } from '@/config';

import { onWindowFocus } from '../utils/onWindowFocus ';

type TermsData = { hcData?: UserHctermsUpdateCheckApiQuery; pfData?: UserTermsPfComfirmApiQuery };

const initTermsData = { hcData: undefined, pfData: undefined };
const getTokenOptions = { scope: 'read:current_user', audience: AUTH0_AUDIENCE };

export const useTermsCheck = (isOpen: boolean) => {
  focusManager.setEventListener(onWindowFocus);
  // state auth0 accessToken 有効期限
  const [expires, setExpires] = useState(-1);

  // state 定期実行apiの最終更新日時
  const [lastUpdateAt, setLastUpdateAt] = useState({ pf: 0, hc: 0 });

  // state 戻り値 定期実行apiの取得データ
  const [termsData, setTermsData] = useState<TermsData>(initTermsData);

  // hook auth0 accessToken取得関数
  const { getAccessTokenSilently: getExpires } = useAuth0();

  // effect 有効期限を非同期で取得し、stateにセット
  useEffect(() => {
    getExpires({ ...getTokenOptions, detailedResponse: true })
      .then(({ expires_in }) => setExpires(expires_in * 1000))
      .catch((e) => console.log(e));
  }, [getExpires]);

  // api ユーザーロールを取得し、HC規約同意が必要なユーザーか判定
  const { data } = useUserRefStatusApiQuery(undefined, {
    select: (d) => {
      return { isHcUser: d.userRole > 1, quitFlag: d.quitFlag };
    },
    cacheTime: 0,
  });

  const enabledPf = expires > -1 && data !== undefined && !data.quitFlag;
  const enabledHc = expires > -1 && data !== undefined && !data.quitFlag && data.isHcUser;

  // api PF利用規約情報取得
  const pfQuery = useUserTermsPfComfirmApiQuery(undefined, {
    refetchInterval: isOpen ? false : expires,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: isOpen,
    cacheTime: expires - 1000,
    enabled: enabledPf,
    select: (d) => (d.updateFlag ? d : undefined),
  });

  // api HC利用規約情報取得
  const hcQuery = useUserHctermsUpdateCheckApiQuery(undefined, {
    refetchInterval: isOpen ? false : expires,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: isOpen,
    cacheTime: expires - 1000,
    enabled: enabledHc,
    select: (d) => (d.termsReconfirmed ? undefined : d),
  });

  // effect 戻り値をセットする
  useEffect(() => {
    // HCユーザの判定終了前は処理しない
    if (data === undefined) return;

    const { data: pfData, status: pfStatus, dataUpdatedAt: pfUpdateAt } = pfQuery;
    const { data: hcData, status: hcStatus, dataUpdatedAt: hcUpdateAt } = hcQuery;

    // PF query fetch判定
    if (pfStatus !== 'success' || lastUpdateAt.pf === pfUpdateAt) return;

    // HC query fetch判定
    if (data.isHcUser) {
      // 必要ユーザーのみ
      if (hcStatus !== 'success' || lastUpdateAt.hc === hcUpdateAt) return;
    }

    // 最終更新日時を更新
    setLastUpdateAt({ pf: pfUpdateAt, hc: hcUpdateAt });

    // 結果セット
    setTermsData({ pfData, hcData });
  }, [hcQuery, data, lastUpdateAt, pfQuery]);

  return termsData;
};
