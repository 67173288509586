import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/checkin_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      unlockNumber: 'ABC1234',
      supportFlag: true,
      supportMessage: 'モックサンプルメッセージ',
    },
  });
};
