import axios, { AxiosResponse } from 'axios';

import { PhotoForm } from '@/components/Form';

export type SavePhotoS3Input = {
  photoForms: PhotoForm[];
  photoResponses: { name: string; url: string }[];
}[];

export const photoPut = (url: string, imageBase64: string) => {
  return axios.put(url, imageBase64);
};

export const savePhotoS3 = (list: SavePhotoS3Input) => {
  const apis: Promise<AxiosResponse<any>>[] = [];

  list.forEach(({ photoForms, photoResponses }) => {
    photoResponses.forEach((x) => {
      const imageBase64 = photoForms.find((y) => y.name === x.name)?.imageBase64;
      if (imageBase64) {
        const api = photoPut(x.url, imageBase64);
        apis.push(api);
      }
    });
  });
  return apis;
};

export const photoDelete = (url: string) => {
  return axios.delete(url);
};

export const deletePhotoS3 = (urlList: string[]) => {
  const apis: Promise<AxiosResponse<any>>[] = [];

  urlList.forEach((url) => {
    const api = photoDelete(url);
    apis.push(api);
  });
  return apis;
};
