import { sprintf } from 'sprintf-js';

export const formatMessage = (
  message: string,
  ...args: string[] | number[] | Date[] | [string | number | Date]
) => {
  return sprintf(message, ...args.map((x) => x.toString()));
};

export const DEFAULT = '入力エラーです';
export const INPUT_ERROR = '入力を確認してください';
export const INTERVAL_ERROR = '%s以上を指定してください';
export const REQUIRED = '必ず入力してください';
export const ZENKAKU_MAX = '%s文字以内の全角で入力してください';
export const ZENKAKU_MIN = '%s文字以上の全角で入力してください';
export const ONEOF = '必ず入力してください';
export const NOTONEOF = '次の値のいずれかであってはなりません: %s';
export const NOTTYPE = '形式が違います';
export const LENGTH = '%s文字入力してください';
export const STR_MIN = '%s文字以上入力してください';
export const STR_MAX = '%s文字以内で入力してください';
export const MATCHES = '形式が違います';
export const EMAIL = '形式が違います';
export const URL = '形式が違います';
export const TRIM = '前後にスペースを入れてはいけません';
export const LOWERCASE = '小文字でなければなりません';
export const UPPERCASE = '大文字でなければなりません';
export const NUM_MIN = '%s以上の値を入力してください';
export const NUM_MAX = '%s以下の値を入力してください';
export const LESS_THAN = '%sより小さい数で入力してください';
export const MORE_THAN = '%sより大きい数で入力してください';
export const POSITIVE = '正の数を入力してください';
export const NEGATIVE = '負の数を入力してください';
export const INTEGER = '整数を入力してください';
export const DECIMAL = '小数を入力してください';
export const DATE_MIN = '%s以上の日付を入力してください';
export const DATE_MAX = '%s以下の日付を入力してください';
export const NO_UNKNOWN = '有効なキーを持ったデータを入力してください';
export const ARR_MIN = '%s個以上の値を入力してください';
export const ARR_MAX = '%s個以下の値を入力してください';
export const ARR_PHOTO_MIN = '%s枚以上の画像を選択してください';
export const ARR_PHOTO_MAX = '%s枚以下の画像を選択してください';
export const ZENKAKU = '全角で入力してください';
export const HANKAKU = '半角英数字で入力してください';
export const ZENKAKU_NOT_SPACE = '空白を含まない全角で入力してください';
export const HANKAKU_NUM_HYPHEN = '半角数字、ハイフンのみで入力してください';
export const DIGITS_LESS_THAN = '小数点以下は%s桁以下にしてください';
export const ZENKAKU_KANA = '全角カタカナで入力してください';
export const ZENKAKU_KANA_MAX_WITH = '%sに合わせて%s文字以内の全角カナで入力してください';
export const ZENKAKU_MAX_WITH = '%sに合わせて%s文字以内の全角で入力してください';
export const ERROR_TITLE_BASE = 'エラーが発生しました';
export const IMAGE_FILE_EXTENSION = 'JPEGの画像をアップロードしてください';
export const HANKAKU_NUM = '半角数字で入力してください';
export const HANKAKU_NUM_LENGTH = '半角数字%s桁で入力してください';
export const HANKAKU_NUM_DIGIT = '%s桁以内の半角数字で入力してください';
export const LESS_THAN_INTEGER = '%sより小さい整数で入力してください';
export const YEN_MIN = '%s円以上で入力してください';
