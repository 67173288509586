import axios, { AxiosResponse } from 'axios';

type GetPhotoS3 = {
  urlList: string[];
};

export const getPhotoS3 = (url: string) => {
  return axios.get(url);
};

export const getPhotosS3 = ({ urlList }: GetPhotoS3) => {
  const apis: Promise<AxiosResponse<any>>[] = [];

  urlList.forEach((url) => {
    if (url) {
      const api = getPhotoS3(url);
      apis.push(api);
    }
  });

  return apis;
};
