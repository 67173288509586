import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { ReactNode, useRef } from 'react';

import { useDisclosure } from '@/hooks/useDisclosure';

type MenuGroupProps = {
  children: ReactNode;
  title: string;
  icon?: string;
  onFocusChange?: () => void;
};

export const MenuGroup = ({ icon, children, title, onFocusChange }: MenuGroupProps) => {
  const { isOpen, toggle, open } = useDisclosure();

  const focusOpen = useRef<boolean>(false);

  return (
    <div>
      <div className="h-20 leading-[5rem] text-[1.25rem] text-txwhite-alpha-900 border-solid border-l-4 border-transparent hover:bg-txblack-alpha-150 hover:border-white cursor-pointer">
        <button
          className={clsx(
            'relative w-full h-full text-left bg-no-repeat bg-menu-icon pl-[3.25rem] border-none outline-none focus:outline-none',
            icon
          )}
          onClick={() => {
            if (focusOpen.current) {
              focusOpen.current = false;
            } else {
              toggle();
            }
          }}
          onFocus={() => {
            if (!isOpen) {
              open();
              focusOpen.current = true;
            }
            onFocusChange && onFocusChange();
          }}
        >
          <div>{title}</div>
          <div className="absolute top-8 right-4">
            {isOpen ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </div>
        </button>
      </div>
      <div className={clsx(isOpen ? 'block' : 'opacity-0 h-0 overflow-hidden')}>{children}</div>
    </div>
  );
};
