import clsx from 'clsx';
import { ReactNode, Ref } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

type SubmenuLinkProps = NavLinkProps & {
  children: ReactNode;
  innerRef?: Ref<HTMLAnchorElement> | undefined;
};

export const SubmenuLink = ({ children, to, end, innerRef }: SubmenuLinkProps) => {
  return (
    <NavLink
      to={to}
      end={end}
      className={clsx(
        'block h-[3.75rem] text-lg leading-[3.75rem] pl-[3.25rem] text-txwhite-alpha-900 border-solid border-l-4 border-transparent cursor-pointer',
        'hover:bg-txblack-alpha-150 hover:border-white'
      )}
      activeClassName="active bg-txblack-alpha-150 border-white"
      ref={innerRef}
    >
      <div>{children}</div>
    </NavLink>
  );
};
