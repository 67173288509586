import clsx from 'clsx';
import { ReactNode } from 'react';

const aligns = {
  left: '',
  center: 'justify-center items-center',
};

const directions = {
  row: 'flex-row',
  col: 'flex-col',
};

type FlexProps = {
  children: ReactNode;
  className?: string;
  align?: keyof typeof aligns;
  direction?: keyof typeof directions;
};
export const Flex = ({ children, align = 'left', direction = 'row', className }: FlexProps) => {
  return (
    <div className={clsx('flex', aligns[align], directions[direction], className)}>{children}</div>
  );
};
