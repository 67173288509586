import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/evc_detail_ref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      reservationFlag: true,
      reservationEnabledDatetime: [
        {
          enabledStartDatetime: '2021-11-11 15:00:00',
          enabledEndDatetime: '2021-11-11 16:00:00',
        },
        {
          enabledStartDatetime: '',
          enabledEndDatetime: '',
        },
        {
          enabledStartDatetime: '',
          enabledEndDatetime: '',
        },
        {
          enabledStartDatetime: '',
          enabledEndDatetime: '',
        },
        {
          enabledStartDatetime: '',
          enabledEndDatetime: '',
        },
      ],
      reservationLessEnabledDatetime: {
        reservationStartTimeWeekday: '',
        reservationEndTimeWeekday: '',
        reservationStartTimeHoliday: '',
        reservationEndTimeHoliday: '',
        mondayFlag: true,
        tuesdayFlag: true,
        wednesdayFlag: true,
        thursdayFlag: true,
        fridayFlag: true,
        saturdayFlag: true,
        sundayFlag: false,
      },
      weekdayDayFee: 500,
      weekdayNightFee: 450,
      holidayDayFee: 500,
      holidayNightFee: 450,
      // NOTE: α版では不要
      // maxChargeFee: '',
      // homeUseFee: '',
      // facDiscountFee: '',
      // carParkAddtionalFlag: '',
      supportFlag: true,
      supportMessage: 'Test Message',
      autoReservationFlag: true,
    },
  });
};
