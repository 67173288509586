import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/ci_user_ref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      checkInFlag: true,
      checkInDatetime: '2021-11-11 15:00:00',
      reservationEndDatetime: '2021-11-11 16:00:00',
      reservationExcessFlag: true,
    },
  });
};
