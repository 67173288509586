import { RouteProps as RoutePropsOrg } from 'react-router';
import { Route, Routes as RoutesOrg } from 'react-router-dom';

export type RouteProps = RoutePropsOrg & {
  title: string;
};

export const Routes = ({ routes }: { routes: RouteProps[] }) => {
  return (
    <RoutesOrg>
      {routes.map((route, key) => (
        <Route key={key} {...route} />
      ))}
    </RoutesOrg>
  );
};
