import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { InfoList } from '../components/InfoList';

import '@/style/reservation_list.scss';

export const InfoRoutes: FC = () => {
  return (
    <Routes>
      <Route path="" element={<InfoList />} />
    </Routes>
  );
};
