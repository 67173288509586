import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';

import {
  UserAddApiMutationVariables,
  useUserAddApiMutation,
} from '@/api/user_add_api/useUserAddApiMutation';
import { SimpleCard } from '@/components/Elements';
import { FlowForm } from '@/components/Form';
import { PublicLayout } from '@/components/Layout/PublicLayout';

import { UserAddFormType } from '../type/UserAddFormType';
import { schemaUserAdd } from '../yup/schema';

import { UserAddComplete } from './UserAddComplete';
import { UserAddConfirm } from './UserAddConfirm';
import { UserAddInput } from './UserAddInput';

export const UserAdd = () => {
  // api ユーザ登録
  const userAdd = useUserAddApiMutation();

  const { user } = useAuth0();

  return (
    <>
      <PublicLayout>
        {user?.email && (
          <SimpleCard>
            <div className="mx-auto">
              <FlowForm<UserAddFormType>
                onSubmit={async (values) => {
                  const padMonth = _.padStart(values.birthMonth, 2, '0');
                  const padDate = _.padStart(values.birthDate, 2, '0');
                  const input: UserAddApiMutationVariables = {
                    nickName: values.nickName,
                    surname: values.surname,
                    name: values.name,
                    surnameRuby: values.surnameRuby,
                    nameRuby: values.nameRuby,
                    postCode: values.postCode,
                    prefectures: values.prefectures,
                    municipalities: values.municipalities,
                    address: values.address,
                    buildingName: values.buildingName,
                    birthday: `${values.birthYear}-${padMonth}-${padDate}`,
                    hostRegistrationNumber: values.hostRegistrationNumber,
                    userKind: values.userKind,
                    mailAddress: user.email ?? '',
                    uuid: '',
                    phoneDeviceId: '',
                    agreedPolicyVersion: '',
                  };
                  if (values.userKind) {
                    input.corporateName = values.corporateName;
                    input.corporateNameRuby = values.corporateNameRuby;
                  }
                  try {
                    await userAdd.mutateAsync(input);
                    return true;
                  } catch (e) {
                    console.log(e);
                    return false;
                  }
                }}
                input={(methods) => <UserAddInput methods={methods} />}
                confirm={(methods, back) => (
                  <UserAddConfirm methods={methods} back={back} isLoading={userAdd.isLoading} />
                )}
                complete={() => <UserAddComplete />}
                schema={schemaUserAdd}
                options={{ defaultValues: { userKind: false } }}
              />
            </div>
          </SimpleCard>
        )}
      </PublicLayout>
    </>
  );
};
