import { Dialog as UIDialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import * as React from 'react';

import 'intersection-observer';
import { useDisclosure } from '@/hooks/useDisclosure';

import { XIcon } from '../Icon';

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  initialFocus?: React.MutableRefObject<null>;
  title?: string;
  overlayCloseable: boolean;
};

export const DialogTitle = UIDialog.Title;

export const DialogDescription = UIDialog.Description;

export const Dialog = ({
  isOpen,
  onClose,
  children,
  initialFocus,
  overlayCloseable,
}: DialogProps) => {
  return (
    <>
      <Transition.Root show={isOpen} as={React.Fragment}>
        <UIDialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={isOpen}
          onClose={onClose}
          initialFocus={initialFocus}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <UIDialog.Overlay
                className={`fixed inset-0 bg-base-400 bg-opacity-75 transition-opacity ${
                  overlayCloseable ? '' : 'pointer-events-none'
                }`}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </UIDialog>
      </Transition.Root>
    </>
  );
};

const sizes = {
  base: 'w-dialog',
  auto: 'w-auto',
};

type DialogOutletProps = {
  children: React.ReactNode;
  className?: string;
  size?: keyof typeof sizes;
  title?: string | React.ReactNode;
  initialFocus?: React.MutableRefObject<null>;
  closeable?: boolean;
  overlayCloseable?: boolean;
};

export const useDialog = () => {
  const { close, open, isOpen } = useDisclosure();

  const DialogOutlet = ({
    children,
    initialFocus,
    title,
    size = 'base',
    closeable = true,
    overlayCloseable = true,
  }: DialogOutletProps) => (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      initialFocus={initialFocus}
      overlayCloseable={overlayCloseable}
    >
      <div
        className={clsx(
          'inline-block align-bottom bg-white rounded-lg px-4 py-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle',
          sizes[size]
        )}
      >
        {closeable && (
          <div className="text-right h-6">
            <button onClick={close} tabIndex={-1}>
              <XIcon className="w-6 h-6 text-base-900" />
            </button>
          </div>
        )}
        {title && (
          <div className="mt-4 text-center">
            <DialogTitle as="h3" className="text-lg leading-6 font-medium">
              {title}
            </DialogTitle>
          </div>
        )}
        <div className="mt-4">{children}</div>
      </div>
    </Dialog>
  );

  return { Dialog: DialogOutlet, close, open, isOpen };
};
