import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useState, useCallback } from 'react';
import { useForm, UseFormReturn, SubmitHandler, UseFormProps } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

type FlowFormProps<TFormValues> = {
  className?: string;
  onSubmit: SubmitHandler<TFormValues>;
  children?: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  options?: UseFormProps<TFormValues>;
  id?: string;
  schema?: AnyObjectSchema;
  input: (methods: UseFormReturn<TFormValues>, clear?: () => void) => React.ReactNode;
  confirm: (methods: UseFormReturn<TFormValues>, back?: () => void) => React.ReactNode;
  complete: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
};

export const FlowForm = <TFormValues extends Record<string, unknown> = Record<string, unknown>>({
  onSubmit,
  options,
  id,
  schema,
  children,
  input,
  confirm,
  complete,
}: FlowFormProps<TFormValues>) => {
  const methods = useForm<TFormValues>({
    ...options,
    resolver: schema && yupResolver(schema),
    reValidateMode: 'onSubmit',
  });
  const [mode, setMode] = useState<'input' | 'confirm' | 'complete'>('input');

  const back = useCallback(() => {
    setMode('input');
  }, []);
  const clear = useCallback(() => {
    methods.clearErrors();
    methods.reset();
    setMode('input');
  }, [methods]);

  React.useEffect(() => {
    document.getElementById('main-scroll')?.scrollTo(0, 0);
  }, [mode]);

  return (
    <form
      onSubmit={methods.handleSubmit(async (values) => {
        if (mode === 'input') setMode('confirm');
        if (mode === 'confirm') {
          const isSuccess = await onSubmit(values);
          if (isSuccess) {
            setMode('complete');
          }
        }
        if (mode === 'complete') setMode('input');
      })}
      id={id}
      noValidate
    >
      {mode === 'input' && input(methods, clear)}
      {mode === 'confirm' && confirm(methods, back)}
      {mode === 'complete' && complete(methods)}
      {children}
    </form>
  );
};
