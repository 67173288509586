import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';
import { UnitLabel } from './UnitLabel';

const sizes = {
  auto: 'form-select flex-auto',
  full: 'form-select w-full',
  sm: 'form-select w-40',
  md: 'form-select w-80',
  lg: 'form-select w-120',
};

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'date';
  className?: string;
  unit?: string;
  registration: Partial<UseFormRegisterReturn>;
  placeholder?: string;
  disabled?: boolean;
  size?: keyof typeof sizes;
  min?: string;
  max?: string;
  supplement?: string;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = 'text',
    label,
    registration,
    error,
    required,
    unit,
    placeholder,
    disabled = false,
    size = 'full',
    className,
    min,
    max,
    supplement,
  } = props;
  return (
    <FieldWrapper label={label} error={error} required={required}>
      {unit ? (
        <UnitLabel unit={unit}>
          {supplement && <p className="text-sm mb-1">{supplement}</p>}
          <input
            type={type}
            {...registration}
            placeholder={placeholder}
            className={clsx('form-input w-full', sizes[size], className)}
            disabled={disabled}
            min={min}
            max={max}
          />
        </UnitLabel>
      ) : (
        <>
          {supplement && <p className="text-sm mb-1">{supplement}</p>}
          <input
            type={type}
            {...registration}
            placeholder={placeholder}
            className={clsx('form-input w-full', sizes[size], className)}
            disabled={disabled}
            min={min}
            max={max}
          />
        </>
      )}
    </FieldWrapper>
  );
};
