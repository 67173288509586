import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type TextAreaFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  placeholder?: string;
};

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { label, registration, error, required, placeholder } = props;
  return (
    <FieldWrapper label={label} error={error} required={required}>
      <textarea
        className={clsx('form-textarea w-full h-28 p-4 mb-4')}
        {...registration}
        placeholder={placeholder}
      />
    </FieldWrapper>
  );
};
