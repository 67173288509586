import { useNotices } from '@/features/info';

import { MenuGroup } from './MenuGroup';
import { MenuLink } from './MenuLink';
import { SubmenuLink } from './SubmenuLink';

export const Sidemenu = () => {
  const { unreadCount } = useNotices();
  return (
    <>
      <div
        className="relative flex bg-sidemenu w-60 flex-col overflow-y-hidden"
        style={{ height: 'calc(100vh - 63px)' }}
      >
        <MenuLink to="/app/dashboard" end icon="bg-icon_home_line">
          ホーム
        </MenuLink>
        <MenuLink to="/app/info" end icon="bg-icon_notification_line">
          お知らせ
          {unreadCount > 0 && (
            <span className="bg-red-600 text-white ml-3 px-2 py-1 text-sm rounded-2xl">
              {unreadCount > 99 ? unreadCount + '+' : unreadCount}
            </span>
          )}
        </MenuLink>
        <MenuLink to="/app/rsv/list" end icon="bg-icon-calendar-line">
          予約管理
        </MenuLink>
        <MenuLink to="/app/earnings" end icon="bg-icon-chart-line">
          収支状況
        </MenuLink>
        <MenuGroup icon="bg-icon-evstation-line" title="設備管理">
          <SubmenuLink to="/app/evc" end>
            設備設定
          </SubmenuLink>
          <SubmenuLink to="/app/evc/manager" end>
            管理者設定
          </SubmenuLink>
          <SubmenuLink to="/app/evc/specific" end>
            特定ユーザー設定
          </SubmenuLink>
        </MenuGroup>
        <MenuLink to="/app/mypage" end icon="bg-icon-user-line">
          マイページ
        </MenuLink>
        <MenuLink
          to="https://support.charger-share.everiwa.jp/hc/ja/sections/16348421071385-%E7%B7%8A%E6%80%A5%E9%80%A3%E7%B5%A1%E5%85%88"
          end
          icon="bg-icon_phone_line"
          newTab
          fullPath
        >
          緊急連絡先
        </MenuLink>
        <MenuLink
          to="https://support.charger-share.everiwa.jp/hc/ja"
          end
          icon="bg-icon-help"
          newTab
          fullPath
        >
          よくある質問
        </MenuLink>
      </div>
    </>
  );
};
