import clsx from 'clsx';

type CardProps = {
  children: React.ReactNode;
  ml?: string;
  mr?: string;
};

export const Card = ({ children, ml = 'ml-4', mr = 'mr-4' }: CardProps) => {
  return (
    <>
      <div className={clsx(ml, mr, 'rounded', 'bg-white shadow')}>{children}</div>
    </>
  );
};
