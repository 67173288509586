import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/rsv_user_refemp_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      enabledRsvData: [
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-16 01:00:00',
          endDatetime: '2022-06-16 02:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-16 20:00:00',
          endDatetime: '2022-06-17 00:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-16 23:00:00',
          endDatetime: '2022-06-17 01:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-17 01:00:00',
          endDatetime: '2022-06-17 02:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-17 03:00:00',
          endDatetime: '2022-06-17 05:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-17 12:00:00',
          endDatetime: '2022-06-17 12:30:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-18 09:00:00',
          endDatetime: '2022-06-19 09:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-19 10:00:00',
          endDatetime: '2022-06-22 15:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-23 01:00:00',
          endDatetime: '2022-06-24 00:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-06-25 00:00:00',
          endDatetime: '2022-06-26 00:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-07-16 23:00:00',
          endDatetime: '2022-07-17 02:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-07-17 00:00:00',
          endDatetime: '2022-07-17 09:00:00',
        },
        {
          isAvailableReserve: true,
          startDatetime: '2022-07-17 10:00:00',
          endDatetime: '2022-07-17 15:00:00',
        },
      ],
      nonReserveDate: ['2022-06-17', '2022-06-18'],
    },
  });
};
