import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type MapApikeyGetApiQueryVariables = {
  osType: number;
};

export type MapApikeyGetApiQuery = {
  apiKey: string;
};

export const MapApikeyGetApiErrType = {
  0: 'エラー無し',
  1: '入力値不正',
  2: '該当ユーザ無',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

export const MapApikeyGetApiOsType = {
  PC: 3,
};

type MapApikeyGetApiErrTypeKey = keyof typeof MapApikeyGetApiErrType;

export const useMapApikeyGetApiQuery = <TData = MapApikeyGetApiQuery>(
  variables: MapApikeyGetApiQueryVariables,
  options?: UseQueryOptions<MapApikeyGetApiQuery, ApiError<MapApikeyGetApiErrTypeKey>, TData>
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useQuery<MapApikeyGetApiQuery, ApiError<MapApikeyGetApiErrTypeKey>, TData>(
    ['map_apikey_get_api', variables],
    useFetchData<
      MapApikeyGetApiQuery,
      ApiError<MapApikeyGetApiErrTypeKey>,
      MapApikeyGetApiQueryVariables
    >('map_apikey_get_api').bind(null, variables),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert('問題が発生しました。（S200E001） 問い合わせフォームよりご連絡ください。');
              break;
            case 2:
              alert('アクセスに失敗しました。再度ログインしてください。');
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
