import { TermsProvider } from '@/features/terms';

import { Footer } from './Footer';
import { ProtectedHeader } from './Header';
import { Sidemenu } from './Sidemenu';

type LayoutProps = {
  children: React.ReactNode;
  isDashboard?: boolean;
};

export const ProtectedLayout = ({ children, isDashboard }: LayoutProps) => {
  return (
    <TermsProvider>
      <div className="wrapper">
        <div className="main">
          <ProtectedHeader />
          <div className="w-full flex flex-row">
            <Sidemenu />
            <div
              id="main-scroll"
              className="relative overflow-y-scroll"
              style={{
                height: 'calc(100vh - 63px)',
                width: 'calc(100vw - 15rem)',
              }}
            >
              <div id={isDashboard ? 'container_main_section-2' : 'container_main_section'}>
                {children}
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </TermsProvider>
  );
};
