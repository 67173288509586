import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '@/api';
import { useFetchData } from '@/api/_common/fetcher';
import { useApiErrorHandler } from '@/api/_common/useApiErrorHandler';
import {
  UserRefStatusApiErrType,
  UserRefStatusApiQuery,
} from '@/api/user_ref_status_api/useUserRefStatusApiQuery';

export const useUserRefStatusApiQuery = <TData = UserRefStatusApiQuery>(
  variables: undefined,
  options?: UseQueryOptions<
    UserRefStatusApiQuery,
    ApiError<keyof typeof UserRefStatusApiErrType>,
    TData
  >
) => {
  const { errorResolve } = useApiErrorHandler();

  return useQuery<UserRefStatusApiQuery, ApiError<keyof typeof UserRefStatusApiErrType>, TData>(
    ['user_ref_status_api', variables],
    useFetchData<UserRefStatusApiQuery, ApiError<keyof typeof UserRefStatusApiErrType>, undefined>(
      'user_ref_status_api'
    ).bind(null, variables),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        errorResolve(err);
      },
    }
  );
};
