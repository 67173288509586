import { ReactNode } from 'react';

type ItmeProps = {
  children: ReactNode;
  className?: string;
};

export const Item = ({ children, className }: ItmeProps) => {
  return <div className={className}>{children}</div>;
};
