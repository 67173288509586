import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import Jimp from 'jimp/browser/lib/jimp';
import React, { ReactNode, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { Control, Controller, ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import { JpegBase64Prefix } from '@/lib/JpegBase64Prefix';

import { SquareImageViewOnly } from '../Elements';

import { FieldWrapper } from './FieldWrapper';

interface Props {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
  limit: number;
  length: number;
}

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  minHeight: '192px',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '1rem',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#00000033',
  borderStyle: 'dashed',
  backgroundColor: '#f2f2f2',
  color: '#00000099',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginTop: '1.25rem',
};

const activeStyle: React.CSSProperties = {
  borderColor: '#2196f3',
};

const acceptStyle: React.CSSProperties = {
  borderColor: '#00e676',
};

const rejectStyle: React.CSSProperties = {
  borderColor: '#ff10744',
};

export type PhotoForm = {
  name: string;
  imageBase64: string;
};

const reducer = (
  state: PhotoForm[],
  action: { type: 'add' | 'delete'; field: ControllerRenderProps<any, any>; data: PhotoForm }
) => {
  const { type, field, data } = action;

  let files: PhotoForm[] = state;
  if (type === 'add') {
    files = [...state, data];
  } else if (type === 'delete') {
    const index = state.indexOf(data);
    files = [...state.slice(0, index), ...state.slice(index + 1)];
    files.forEach((file, i) => {
      file.name = `photo${('00' + (i + 1).toString()).slice(-2)}`;
    });
  }
  field.onChange(files);
  return files;
};

const initialFiles: PhotoForm[] = [];

type ImageFieldProps = {
  name: string;
  label: string;
  control: Control<any>;
  limit: number;
  required?: boolean;
  length: number;
};

export const ImageField = ({ name, label, control, limit, required, length }: ImageFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <FieldWrapper label={label} error={fieldState.error} required={required}>
            <ImageFieldBase field={field} fieldState={fieldState} limit={limit} length={length} />
          </FieldWrapper>
        );
      }}
    />
  );
};

export const ImageFieldBase = ({ field, limit, length }: Props) => {
  const [error, setError] = useState<ReactNode>();
  const [files, dispatchFiles] = useReducer(reducer, field.value ?? initialFiles);

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      const remainNum = limit - (files?.length ?? 0);
      acceptedFiles.map((file, i) => {
        if (remainNum > i) {
          const url = URL.createObjectURL(file);
          Jimp.read(url).then((image) => {
            const height = image.getHeight();
            const width = image.getWidth();

            let rate = 1;

            if (height > 1000 || width > 1000) {
              const _rate = 1000 / Math.ceil(height > width ? height : width);
              rate = Math.ceil(_rate * Math.pow(10, 3)) / Math.pow(10, 3);
            }

            image.scale(rate).getBase64(Jimp.MIME_JPEG, (err, src) => {
              const data: PhotoForm = {
                name: `photo${('00' + ((field.value?.length ?? 0) + i + 1)).slice(-2)}`,
                imageBase64: src.replace(JpegBase64Prefix, ''),
              };
              dispatchFiles({
                field: field,
                data: data,
                type: 'add',
              });
            });
          });
        }
      });
    },
    [field, files.length, limit]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } =
    useDropzone({
      accept: {
        'image/jpeg': [],
      },
      onDrop,
      maxFiles: limit,
    });

  useEffect(() => {
    fileRejections.forEach(({ errors }) => {
      errors.forEach((err) => {
        console.log(err);
        if (err.code === 'file-invalid-type') {
          setError(<>{'JPEGの画像をアップロードしてください'}</>);
        }
      });
    });
  }, [fileRejections]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  return (
    <>
      <div className="grid grid-cols-5 gap-3">
        {field.value && field.value.length > 0 && (
          <>
            {files.map((file) => {
              return (
                <>
                  <SquareImageViewOnly src={JpegBase64Prefix + file.imageBase64} length={length}>
                    <button
                      type="button"
                      className="absolute right-0"
                      onClick={() => {
                        dispatchFiles({
                          field: field,
                          data: file,
                          type: 'delete',
                        });
                      }}
                    >
                      <XIcon className="h-6 w-6 text-base-600" />
                    </button>
                  </SquareImageViewOnly>
                </>
              );
            })}
          </>
        )}
      </div>
      {limit - (files?.length ?? 0) > 0 && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <i />
          {error ? (
            <>
              <div className="mb-2">
                <img src="/assets/img/icon-image.png" alt="" />
              </div>
              <div className="mb-2 text-sm">写真をここにドラッグ＆ドロップ</div>
              <div className="mb-4 text-sm text-error">{error}</div>
              <div
                className={clsx(
                  'flex justify-center items-center w-50 h-13 border border-main-700 rounded text-main-700 bg-white shadow'
                )}
              >
                <img src="/assets/img/icon_add_line.png" alt="" />
                <div className="ml-2">ファイルを選択</div>
              </div>
            </>
          ) : (
            <>
              <div className="mb-2">
                <img src="/assets/img/icon-image.png" alt="" />
              </div>
              <div className="mb-2 text-sm">写真をここにドラッグ＆ドロップ</div>
              <div className="mb-4 text-sm">または</div>
              <div
                className={clsx(
                  'flex justify-center items-center w-50 h-13 border border-main-700 rounded text-main-700 bg-white shadow'
                )}
              >
                <img src="/assets/img/icon_add_line.png" alt="" />
                <div className="ml-2">ファイルを選択</div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
