import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/evc_place_listref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      listNum: 4,
      listData: [
        {
          placeId: '1',
          placeName: '公園前',
          evcNum: 4,
          evcData: [
            {
              evcEquipmentId: '101',
              evcNickName: '設備A',
            },
            {
              evcEquipmentId: '102',
              evcNickName: '設備B',
            },
            {
              evcEquipmentId: '103',
              evcNickName: '設備C',
            },
            {
              evcEquipmentId: '104',
              evcNickName: '設備D',
            },
          ],
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PLACE/place01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=9996b6225fedaacadbb36cba1dced76fde3a11cb3f449832f0381d79dc3bbcd4',
        },
        {
          placeId: '2',
          placeName: '交差点付近',
          evcNum: 1,
          evcData: [
            {
              evcEquipmentId: '201',
              evcNickName: '設備AAA',
            },
          ],
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PLACE/place01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=9996b6225fedaacadbb36cba1dced76fde3a11cb3f449832f0381d79dc3bbcd4',
        },
        {
          placeId: '3',
          placeName: '交差点付近2',
          evcNum: 1,
          evcData: [
            {
              evcEquipmentId: '201',
              evcNickName: '設備AAA',
            },
          ],
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PLACE/place01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=9996b6225fedaacadbb36cba1dced76fde3a11cb3f449832f0381d79dc3bbcd4',
        },
        {
          placeId: '4',
          placeName: '交差点付近3',
          evcNum: 1,
          evcData: [
            {
              evcEquipmentId: '201',
              evcNickName: '設備AAA',
            },
          ],
          evcPhotoGetUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PLACE/place01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004414Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=9996b6225fedaacadbb36cba1dced76fde3a11cb3f449832f0381d79dc3bbcd4',
        },
      ],
    },
  });
};
