import { useMutation, UseMutationOptions } from 'react-query';

import { ApiError } from '../_common/ApiError';
import { useFetchData } from '../_common/fetcher';
import { useApiErrorAlert } from '../_common/useApiErrorAlert';
import { useApiErrorHandler } from '../_common/useApiErrorHandler';

export type UserAddApiMutationVariables = {
  nickName: string;
  surname: string;
  name: string;
  surnameRuby: string;
  nameRuby: string;
  postCode: string;
  prefectures: string;
  municipalities: string;
  address: string;
  buildingName?: string;
  birthday: string;
  hostRegistrationNumber: string;
  corporateName?: string;
  corporateNameRuby?: string;
  userKind: boolean;
  mailAddress: string;
  phoneDeviceId: string;
  uuid: string;
  agreedPolicyVersion: string;
};

export const UserAddApiErrType = {
  0: 'エラー無し',
  1: '入力値不正',
  4: '電話番号取得エラー',
  5: '会員情報登録エラー',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

type UserAddApiErrTypeKey = keyof typeof UserAddApiErrType;

export const useUserAddApiMutation = <TContext = unknown>(
  options?: UseMutationOptions<
    undefined,
    ApiError<UserAddApiErrTypeKey>,
    UserAddApiMutationVariables,
    TContext
  >
) => {
  const { alert } = useApiErrorAlert();
  const { errorResolve } = useApiErrorHandler();

  return useMutation<
    undefined,
    ApiError<UserAddApiErrTypeKey>,
    UserAddApiMutationVariables,
    TContext
  >(
    'user_add_api',
    useFetchData<undefined, ApiError<UserAddApiErrTypeKey>, UserAddApiMutationVariables>(
      'user_add_api'
    ),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              alert(`${err.errMsg}が不正です。再入力してください。`);
              break;
            case 4:
              alert('電話番号の取得に失敗しました。しばらく経ってからやり直してください。');
              break;
            default:
              alert(
                `問題が発生しました。(S200E${err.errCode})\n問い合わせフォームよりご連絡ください。`
              );
          }
        }
      },
    }
  );
};
