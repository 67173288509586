import { RefObject, useEffect, useState } from 'react';
import { UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';

import { useUserGeocodingApiQuery } from '@/api/user_geocoding_api/useUserGeocodingApiQuery';

import { Button } from '../Elements';

type AddressSearchButtonProps = {
  postCode: string;
  colorScheme?: string;
  setErrorFieldName: string;
  setValuePrefectures: string;
  setValueMunicipalities: string;
  clearErrors: UseFormClearErrors<any>;
  setValue: UseFormSetValue<any>;
  setError: UseFormSetError<any>;
  prefecturesRef: RefObject<HTMLSelectElement>;
  disabled?: boolean;
};

export const AddressSearchButton = ({
  postCode,
  clearErrors,
  setErrorFieldName,
  setValuePrefectures,
  setValueMunicipalities,
  setValue,
  setError,
  prefecturesRef,
  disabled,
}: AddressSearchButtonProps) => {
  // state 郵便番号
  const [postalCode, setPostalCode] = useState<string>();

  // query
  const { data, error, refetch, remove } = useUserGeocodingApiQuery(
    { postalCode: postalCode ?? '' },
    { enabled: false }
  );

  // effect
  useEffect(() => {
    if (error) {
      setError(setErrorFieldName, { message: `入力を確認してください` });
    } else if (data && data.address.formatted_address) {
      // API戻り値として以下のパターンが存在するため、フロント側で吸収する
      // 郵便番号住所
      // 郵便番号のみ
      // 住所のみ
      const formatted_address = data.address.formatted_address.split(' ');
      let valuePrefectures = '';
      let valueMunicipalities = '';
      if (formatted_address.length > 0) {
        // 先頭郵便番号判定
        const reg = /[0-9]/;
        if (reg.test(formatted_address[0])) {
          formatted_address.shift();
        }
        const [prefecturesResponse, ...municipalitiesResponse] = formatted_address;
        valuePrefectures = prefecturesResponse ?? '';
        valueMunicipalities = municipalitiesResponse ? municipalitiesResponse.join('') : '';
      }
      // const [, prefecturesResponse, ...municipalitiesResponse] =
      //   data.address.formatted_address.split(' ');
      setValue(setValuePrefectures, valuePrefectures);
      setValue(setValueMunicipalities, valueMunicipalities);

      const hasColorAsh = prefecturesRef.current?.classList.contains('text-base-300');
      if (valuePrefectures === '') {
        !hasColorAsh && prefecturesRef.current?.classList.add('text-base-300');
      } else {
        hasColorAsh && prefecturesRef.current?.classList.remove('text-base-300');
      }
    }
  }, [
    data,
    error,
    prefecturesRef,
    setError,
    setErrorFieldName,
    setValue,
    setValueMunicipalities,
    setValuePrefectures,
  ]);

  // effect
  useEffect(() => {
    if (postalCode) refetch();
  }, [postalCode, refetch]);

  // ボタンonClock
  const handleSubmit = async () => {
    remove();
    if (!postCode) {
      setError(setErrorFieldName, { message: '入力を確認してください' });
      return;
    }
    if (postCode.length !== 7) {
      setError(setErrorFieldName, { message: '7文字で入力してください' });
      return;
    }
    if (!postCode.match(/^\d+$/)) {
      setError(setErrorFieldName, { message: '半角数字のみです' });
      return;
    }

    clearErrors(setErrorFieldName);
    if (postCode === postalCode) {
      refetch();
    } else {
      setPostalCode(postCode);
    }
  };

  return (
    <Button onClick={handleSubmit} variant="inverse" size="form-inline" disabled={disabled}>
      住所を自動入力
    </Button>
  );
};
