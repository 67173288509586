import { useAuth0 } from '@auth0/auth0-react';
import { useState, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserRefStatusApiQuery } from '../api/useUserRefStatusApiQuery';

export const Wrapper = ({ children }: { children: ReactNode }) => {
  const { user, isLoading } = useAuth0();

  const [flg, setFlg] = useState(false);

  const navigate = useNavigate();

  const { status, error, data } = useUserRefStatusApiQuery(undefined, {
    enabled: !isLoading,
    suspense: true,
    useErrorBoundary: false,
  });

  const location = useLocation();

  useEffect(() => {
    if (status === 'error') {
      if (error?.errCode === 1 && user) {
        if (user.email_verified) {
          // アカウント完了ページ;
          if (location.pathname !== '/app/user/add') {
            navigate('/app/user/account');
          }
          setFlg(true);
        } else {
          // メールアドレス確認ページ
          navigate('/user/email');
          setFlg(true);
        }
      }
    }
    if (status === 'success') {
      if (data?.quitFlag) {
        // 退会キャンセルページ
        navigate('/app/mypage/service_quit_cancel');
      }
      if (location.pathname.indexOf('/app/user/') !== -1) {
        navigate('/app/mypage');
      }

      setFlg(true);
    }
  }, [data?.quitFlag, error?.errCode, navigate, status, user, location.pathname]);

  return <>{flg ? children : <></>}</>;
};

export const Callback = () => {
  return (
    <Wrapper>
      <div></div>
    </Wrapper>
  );
};
