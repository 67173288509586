import clsx from 'clsx';

type BreadType = {
  array: { label: string; path: string; now?: boolean }[];
};

export const Bread = ({ array }: BreadType) => {
  return (
    <>
      <div className="mt-6 ml-8 mb-4">
        <ul className={clsx('flex list-none')}>
          {array.map((item, index) => {
            return (
              <div className="flex" key={index}>
                <li className="text-txblack-alpha-900 text-[0.6875rem]">
                  {item.now ? (
                    item.label
                  ) : (
                    <a href={item.path} className={clsx('text-txblack-alpha-600 no-underline')}>
                      {item.label}
                    </a>
                  )}
                </li>
                {index !== array.length - 1 && (
                  <img src="/assets/img/icon_bread_crumb.png" alt="" className="mx-2 my-0" />
                )}
              </div>
            );
          })}
        </ul>
      </div>
    </>
  );
};
