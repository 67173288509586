import { FC } from 'react';
import { useWindowSize } from 'react-use';

import { UserHctermsUpdateCheckApiQuery } from '@/api/user_hcterms_update_check_api/useUserHctermsUpdateCheckApiQuery';

type HousecoinTermsProps = {
  hcData: UserHctermsUpdateCheckApiQuery;
};

export const HousecoinTerms: FC<HousecoinTermsProps> = (props) => {
  const { width, height } = useWindowSize();
  return (
    <>
      <iframe
        title="housecoin_terms_iframe"
        srcDoc={props.hcData.description}
        width={width * 0.8}
        height={height * 0.7}
      ></iframe>
    </>
  );
};
