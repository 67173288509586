import { FC } from 'react';

import { UserAccountComplete as AccountCompleteCom } from '../components/UserAccountComplete';

export const UserAccountComplete: FC = () => {
  return (
    <>
      <AccountCompleteCom />
    </>
  );
};
