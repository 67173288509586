import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/evc_detail_fee_ref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      weekdayDayFee: 1000,
      weekdayNightFee: 2000,
      holidayDayFee: 3000,
      holidayNightFee: 4000,
      holidayTreatment: 1,
      weekdayDayCertainFee: null,
      weekdayNightCertainFee: null,
      holidayDayCertainFee: null,
      holidayNightCertainFee: null,
      certainHolidayTreatment: null,
    },
  });
};
