import { Footer } from './Footer';
import { PublicHeader } from './Header';

type LayoutProps = {
  children: React.ReactNode;
};

export const PublicLayout = ({ children }: LayoutProps) => {
  return (
    <div className="wrapper" style={{ backgroundColor: '#f5f6f9' }}>
      <div className="main">
        <PublicHeader />
        <div
          id="main-scroll"
          className="relative overflow-y-scroll"
          style={{
            height: 'calc(100vh - 63px)',
          }}
        >
          <div id="container_main_section">{children}</div>
          <div className="hidden sc-desktop:block">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
