import clsx from 'clsx';
import { NavLink, NavLinkProps } from 'react-router-dom';

type MenuLinkProps = NavLinkProps & {
  icon?: string;
  newTab?: boolean;
  fullPath?: boolean;
};

export const MenuLink = ({ to, end, children, icon, newTab, fullPath }: MenuLinkProps) => {
  return fullPath ? (
    <>
      <a
        target={newTab ? '_blank' : undefined}
        href={to.toString()}
        className={clsx(
          'block h-20 leading-[5rem] text-[1.25rem] text-txwhite-alpha-900 border-solid border-l-4 border-transparent cursor-pointer',
          'hover:bg-txblack-alpha-150 hover:border-white'
        )}
        rel="noreferrer"
      >
        <div>
          <div className={clsx('bg-no-repeat bg-menu-icon pl-[3.25rem]', icon)}>{children}</div>
        </div>
      </a>
    </>
  ) : (
    <>
      <NavLink
        target={newTab ? '_blank' : undefined}
        to={to}
        end={end}
        className={clsx(
          'block h-20 leading-[5rem] text-[1.25rem] text-txwhite-alpha-900 border-solid border-l-4 border-transparent cursor-pointer',
          'hover:bg-txblack-alpha-150 hover:border-white'
        )}
        activeClassName="active bg-txblack-alpha-150 border-white"
      >
        <div>
          <div className={clsx('bg-no-repeat bg-menu-icon pl-[3.25rem]', icon)}>{children}</div>
        </div>
      </NavLink>
    </>
  );
};
