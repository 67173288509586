import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/evc_update_api').reply(200, {
    result: 0,
    err: [],
    data: {
      evc_equipment_id: 1,
      placePhoto: [
        {
          photoName: 'photo01',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PLACE/place01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=7d3272ede9223307cd6a6e52658aa8829bcf4fae55d339e27e68f2311e78825a',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PLACE/place01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=0a2b82b6f2de13f0799b328b8b64bb45f60826f0d6c6f976537d3278a53dff26',
        },
      ],
      equipmentPhoto: [
        {
          photoName: 'photo01',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=2741a4d40f675f8d7c5ae2a21d8bfcd114cfcb0ebc06259912410dfdd7937a8c',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=342e7dbb7827c56f91d60cf741c0a0740f1d2d0ec3228e09cfbfc4dadf69541b',
        },
        {
          photoName: 'photo02',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment02?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=70f6c62ebadbcb9db90ca9bdcee7941b979847e1e26760d36d825ca6bd393c47',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment02?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=5c79b58029b3ed31886c7aa378f13c0f08136bf2ccf90e0e133b35a697323da6',
        },
        {
          photoName: 'photo03',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment03?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=7b9478b2ee860330fc6dd8b20b5868531c3bb1ea5bc9169b5f7d407d27655a34',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment03?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=8a68d5608432f64b5f7e96cbffbd2a3e711e68318d881b0d617a8d9568ef7fa9',
        },
        {
          photoName: 'photo04',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment04?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=4ac86d0f11c3976c4a965917cc66f995a64a90dbb28fe6595db437bd0d8dfc16',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment04?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=2c8dd4e7948db8d76815139515e2f5acda4161da69945ffc7dcc2c53c92963ab',
        },
        {
          photoName: 'photo05',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment05?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=193c59a92bd5daa405cd2476b977ead0f182bbd5399f02e80e317a0fb6a6ef53',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/EQUIPMENT/equipment05?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=4688236add35d42942fa115603f10ad1ff7c87ac6c45374f264a4f5ccd1fb78d',
        },
      ],
      parkingPhoto: [
        {
          photoName: 'photo01',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=f1e65482ff11018d273f5162ac2786106ec119e42f1cb1e1cfda62b3b4486df0',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking01?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=b3c51d0092b72f226e0c789a4f98cd0ce189d05ebafbe211561edd5870f874e1',
        },
        {
          photoName: 'photo02',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking02?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=a1fbd0d131563c3451e9472e6143df30642ce65cedbff57172a7c9043d8d5c34',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking02?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=061522dcd5a89a1a788d0af62154cdb67e3aecb9d6ad35199b92225136c30a9d',
        },
        {
          photoName: 'photo03',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking03?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=fa16794332c1ef2d91740b2e7735a59ded0358a8c1a7ae0e80b472d41c157980',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking03?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=bc2c0dd1fa6df3350c0fe91fef9ba6cf633cdfa2770f53871d9ea41500e9c2aa',
        },
        {
          photoName: 'photo04',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking04?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004008Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=a2e10054d5cf56ad197ab99989d9e6947c8e146c6cb244651300b41f4860ca88',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking04?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300000&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=87d5d158ce14e0c6c332b804eef25a3a8bf0cddb85a31e30dea7c65bf79694f6',
        },
        {
          photoName: 'photo05',
          evcPhotoPutUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking05?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=3a12dfc5cf5b08301fbe5b5202072c65f425aa5b2545c064f20c6fdf3e752e89',
          evcPhotoDeleteUrl:
            'https://test-evc-photo-bucket.s3.ap-northeast-1.amazonaws.com/500/5/PARKING/parking05?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220602T004743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299999&X-Amz-Credential=AKIA5FBUY2T3TX7YFXTK%2F20220602%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=483fe7c500fcc3a5644d9f264c499699085af2cfb660c1763d160761d79ac5ab',
        },
      ],
    },
  });
};
