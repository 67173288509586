import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { UserTermsPfComfirmApiQuery } from '@/api/user_terms_pf_confirm_api/useUserTermsPfComfirmApiQuery';

type PlatformTermsProps = {
  pfData: UserTermsPfComfirmApiQuery;
};

const url = `https://policy.charger-share.everiwa.jp/policy.html`;

export const PlatformTerms: FC<PlatformTermsProps> = () => {
  const { width, height } = useWindowSize();

  const [termsHtml, setTermsHtml] = useState('');

  useEffect(() => {
    const getPfTermsFromS3 = async () => {
      axios.get(url).then((res) => {
        if (res.data) {
          setTermsHtml(res.data);
        }
      });
    };

    getPfTermsFromS3();
  }, []);

  return (
    <>
      <iframe
        title="platform_terms_iframe"
        srcDoc={termsHtml}
        width={width * 0.8}
        height={height * 0.7}
      ></iframe>
    </>
  );
};
