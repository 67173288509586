import { useEffect, useState } from 'react';

import { Line } from '@/components/Elements/Line/Line';
import { Row, Col } from '@/components/Layout';
import { useDisclosure } from '@/hooks/useDisclosure';

import { Notice } from '../db/db';
import { useNotices } from '../hooks/useNotices';

export const InfoListItem = ({ notice }: { notice: Notice }) => {
  const { update } = useNotices();
  const [opened, setOpened] = useState(false);

  const { isOpen, toggle } = useDisclosure();

  useEffect(() => {
    if (isOpen) setOpened(true);
  }, [isOpen]);

  const rowStyleClass = notice.content
    ? `disclosure-item ${isOpen && notice.content && 'expand'}`
    : 'disclosure-item-no-content';

  return (
    <>
      <Col col={4}>
        <Line mx={'mx-0'}></Line>
        <a
          href="javascript:void(0)"
          className={rowStyleClass}
          onClick={() => {
            if (!isOpen && notice.isRead === '0') {
              update(notice.notificationId);
            }
            toggle();
          }}
        >
          <Row col={48} mt={'mt-4'} mb={'mb-0'} px={'px-4'}>
            <Col col={4}>{notice.sendDatetime.toLocaleDateString()}</Col>
            <Col col={3}>
              <>
                {notice.notificationType === 1 ? (
                  <img src="/assets/img/label_news.png" alt="" className="my-auto inline-block" />
                ) : (
                  <img
                    src="/assets/img/label_reservation.png"
                    alt=""
                    className="my-auto inline-block"
                  />
                )}
              </>
            </Col>
            <Col col={38}>
              {isOpen && notice.content ? (
                <>
                  <div className="h-6 truncate">{notice.title}</div>
                  <div className="text-sm mt-1">{notice.content}</div>
                </>
              ) : (
                <>
                  <div className="h-6 truncate">{notice.title}</div>
                </>
              )}
            </Col>
            <Col col={3}>
              <>
                {notice.isRead === '0' && !opened && (
                  <img src="/assets/img/label_unread.png" alt="" className="my-auto inline-block" />
                )}
              </>
            </Col>
          </Row>
        </a>
      </Col>
    </>
  );
};
