import { useQuery, UseQueryOptions } from 'react-query';

import { ApiError } from '@/api';
import { useFetchData } from '@/api/_common/fetcher';
import { useApiErrorHandler } from '@/api/_common/useApiErrorHandler';

export type UserRefApiQuery = {
  nickName: string;
  dispUserId: string;
  surname: string;
  name: string;
  surnameRuby: string;
  nameRuby: string;
  postCode: string;
  prefectures: string;
  municipalities: string;
  address: string;
  buildingName?: string;
  birthday: string;
  corporateName?: string;
  corporateNameRuby?: string;
  userKind: boolean;
  uuid?: string;
  userRole: number;
};

export const UserRefApiErrType = {
  0: 'エラー無し',
  1: '該当設備無',
  100: 'DBエラー',
  200: 'コンテナ間通信異常',
  999: 'システムエラー',
};

type UserRefApiErrTypeKey = keyof typeof UserRefApiErrType;

export const useUserRefApiQuery = <TData = UserRefApiQuery>(
  variables: undefined,
  options?: UseQueryOptions<UserRefApiQuery, ApiError<UserRefApiErrTypeKey>, TData>
) => {
  const { errorResolve } = useApiErrorHandler();

  return useQuery<UserRefApiQuery, ApiError<UserRefApiErrTypeKey>, TData>(
    ['user_ref_api', variables],
    useFetchData<UserRefApiQuery, ApiError<UserRefApiErrTypeKey>, undefined>('user_ref_api').bind(
      null,
      variables
    ),
    {
      ...options,
      useErrorBoundary: false,
      onError: (err) => {
        if (!errorResolve(err)) {
          switch (err.errCode) {
            case 1:
              console.log(err);
              break;
            default:
              throw err;
          }
        }
      },
    }
  );
};
