import { FC, useCallback, useEffect, useState } from 'react';

import { useUserHctermsReconfirmedApiMutation } from '@/api/user_hcterms_reconfirmed_api/useUserHctermsReconfirmedApiMutation';
import { useUserTermsPfAgreedUpdateApiMutation } from '@/api/user_terms_pf_agreed_update_api/useUserTermsPfAgreedUpdateApiMutation';
import { ButtonGroup, Button } from '@/components/Elements';
import { useLogout } from '@/hooks/useLogout';

import { useTermsCheck } from '../hooks/useTermsCheck';

import { HousecoinTerms } from './HousecoinTerms';
import { PlatformTerms } from './PlatformTerms';

export const TermsDialog: FC = () => {
  // state
  const [mode, setMode] = useState<'pf' | 'hc'>();

  // hook
  const { logout } = useLogout();
  const checkResult = useTermsCheck(mode ? true : false);
  const pfMutation = useUserTermsPfAgreedUpdateApiMutation();
  const hcMutation = useUserHctermsReconfirmedApiMutation();

  // effect
  useEffect(() => {
    if (checkResult.pfData) {
      setMode('pf');
    } else if (checkResult.hcData) {
      setMode('hc');
    } else {
      setMode(undefined);
    }
  }, [checkResult]);

  const { pfData, hcData } = checkResult;

  // callback
  const onAgree = useCallback(() => {
    if (mode === 'pf' && pfData) {
      pfMutation.mutateAsync({ version: pfData.version }).then(() => {
        setMode(hcData ? 'hc' : undefined);
      });
    } else if (mode === 'hc' && hcData) {
      hcMutation.mutateAsync(undefined).then(() => {
        setMode(undefined);
      });
    }
  }, [hcData, hcMutation, mode, pfData, pfMutation]);

  return (
    <>
      {mode && (
        <>
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
              <div className="fixed inset-0 bg-base-400 bg-opacity-75 transition-opacity"></div>
              <div
                className={`inline-block align-bottom bg-white rounded-lg px-4 py-6 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle my-auto mx-auto w-auto`}
              >
                <div className="mt-4 text-center mb-10">
                  <h3 className="text-lg leading-6 font-medium">
                    {mode === 'pf'
                      ? 'everiwa利用規約・プライバシーポリシー改定のお知らせ'
                      : 'everiwa wallet利用規約・情報共有同意改定のお知らせ'}
                  </h3>
                </div>
                <div className="my-8 flex justify-center">
                  {mode === 'pf' && pfData && <PlatformTerms pfData={pfData} />}
                  {mode === 'hc' && hcData && <HousecoinTerms hcData={hcData} />}
                </div>
                <ButtonGroup align="center">
                  <Button
                    type="button"
                    onClick={() =>
                      logout({ returnTo: window.location.origin + '/logout_callback/' })
                    }
                    variant="inverse"
                  >
                    同意しない
                  </Button>
                  <Button type="button" onClick={onAgree}>
                    同意する
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
