import clsx from 'clsx';

import { CircleIcon } from './CircleIcon';
import { UserLineIcon } from './UserLineIcon';

export const UserIcon = ({ className }: { className?: string }) => {
  return (
    <div className={clsx('relative', className)}>
      <div className="absolute w-full h-full">
        <CircleIcon />
      </div>
      <div className="stack-center w-4/5 h-4/5">
        <UserLineIcon />
      </div>
    </div>
  );
};
