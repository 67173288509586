import { UseFormReturn } from 'react-hook-form';

import { Button } from '@/components/Elements';
import { ButtonGroup } from '@/components/Elements/Button/ButtonGroup';
import { Line } from '@/components/Elements/Line/Line';
import { Title } from '@/components/Elements/Title/Title';
import { Container, Row, Col } from '@/components/Layout/Grid';
import { formatPostcode } from '@/utils/formatPostcode';

import { UserAddFormType } from '../type/UserAddFormType';

type InputProps = {
  methods: UseFormReturn<any>;
  back?: () => void;
  isLoading: boolean;
};

export const UserAddConfirm = ({ methods, back, isLoading }: InputProps) => {
  const { getValues } = methods;
  const values: UserAddFormType = getValues();

  return (
    <>
      <Title className="text-center font-bold ml-0 mr-0" mx="mx-0">
        登録内容確認
      </Title>
      <img src="/assets/img/icon_user_line_circle_80.png" className="my-8 w-max mx-auto" alt="" />
      <img src="/assets/img/flow_04.png" className="w-max mx-auto" alt="" />
      <p className="text-center font-medium text-lg mt-10">会員情報</p>
      <Container px="px-60">
        <Line mx="mx-0" />
        <Row col={6} mb="mb-4" mt="mt-4">
          <Col col={2}>
            <p className="text-txblack-alpha-600">ニックネーム</p>
          </Col>
          <Col col={4}>{values.nickName}</Col>
        </Row>
        <Line mx="mx-0" />
        <Row col={6} mb="mb-4" mt="mt-4">
          <Col col={2}>
            <p className="text-txblack-alpha-600">氏名</p>
          </Col>
          <Col col={4}>{`${values.surname} ${values.name}`}</Col>
        </Row>
        <Line mx="mx-0" />
        <Row col={6} mb="mb-4" mt="mt-4">
          <Col col={2}>
            <p className="text-txblack-alpha-600">氏名（カタカナ）</p>
          </Col>
          <Col col={4}>{`${values.surnameRuby} ${values.nameRuby}`}</Col>
        </Row>
        <Line mx="mx-0" />
        <Row col={6} mb="mb-4" mt="mt-4">
          <Col col={2}>
            <p className="text-txblack-alpha-600">郵便番号</p>
          </Col>
          <Col col={4}>{`〒${formatPostcode(values.postCode)}`}</Col>
        </Row>
        <Line mx="mx-0" />
        <Row col={6} mb="mb-4" mt="mt-4">
          <Col col={2}>
            <p className="text-txblack-alpha-600">住所</p>
          </Col>
          <Col col={4}>
            {`${values.prefectures} ${values.municipalities} ${values.address} ${values.buildingName}`}
          </Col>
        </Row>
        <Line mx="mx-0" />
        <Row col={6} mb="mb-4" mt="mt-4">
          <Col col={2}>
            <p className="text-txblack-alpha-600">生年月日</p>
          </Col>
          <Col col={4}>{`${values.birthYear}年${values.birthMonth}月${values.birthDate}日`}</Col>
        </Row>
        <Line mx="mx-0" />
        <Row col={6} mb="mb-4" mt="mt-4">
          <Col col={2}>
            <p className="text-txblack-alpha-600">登録番号</p>
          </Col>
          <Col col={4}>{values.hostRegistrationNumber || '登録なし'}</Col>
        </Row>
        <Line mx="mx-0" />
        {values.userKind && (
          <>
            <Row col={6} mb="mb-4" mt="mt-4">
              <Col col={2}>
                <p className="text-txblack-alpha-600">法人名</p>
              </Col>
              <Col col={4}>{`${values.corporateName}`}</Col>
            </Row>
            <Line mx="mx-0" />
            <Row col={6} mb="mb-4" mt="mt-4">
              <Col col={2}>
                <p className="text-txblack-alpha-600">法人名（カタカナ）</p>
              </Col>
              <Col col={4}>{`${values.corporateNameRuby}`}</Col>
            </Row>
            <Line mx="mx-0" />
          </>
        )}
      </Container>
      <div className="mb-12 mt-6">
        <ButtonGroup align="center">
          <Button type="button" variant="inverse" onClick={back}>
            内容を修正
          </Button>
          <Button type="submit" disabled={isLoading}>
            この内容で登録
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
};
