import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/user_ref_status_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: { quitFlag: false },
  });
};
