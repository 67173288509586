type UnitLabelProps = {
  unit: string;
  children: React.ReactNode;
};

export const UnitLabel = ({ unit, children }: UnitLabelProps) => {
  return (
    <div className="flex w-full gap-1">
      <div className="flex-grow">{children}</div>
      <div className="w-12">
        <div className="flex items-end h-full">
          <div className="h-6 pb-2 pl-2">{unit}</div>
        </div>
      </div>
    </div>
  );
};
