import Jimp from 'jimp/browser/lib/jimp';
import { ChangeEvent } from 'react';

import { photoPut } from '@/api/_common/savePhotoS3';
import { useUserPhotoUrlRefApiQuery } from '@/api/user_photo_url_ref_api/useUserPhotoUrlRefApiQuery';
import { useConfirm } from '@/components/Confirm';
import { JpegBase64Prefix } from '@/lib/JpegBase64Prefix';
import { ERROR_TITLE_BASE, IMAGE_FILE_EXTENSION } from '@/lib/messages';

import { ImageView } from '../Image';

import { UserIcon } from './UserIcon';

export type EditableNicknameIconProps = {
  nickName: string;
  labels?: string[];
  imageUrl?: string;
  refetch?: () => void;
  userId?: string;
};

export const EditableNicknameIcon = ({
  nickName,
  labels,
  imageUrl,
  refetch,
  userId,
}: EditableNicknameIconProps) => {
  const { data } = useUserPhotoUrlRefApiQuery(undefined, {});

  const { confirm } = useConfirm();

  const imageChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && evt.target.files.length > 0) {
      try {
        const fileType = evt.target.files[0].name.split('.').pop();

        if (fileType === 'jpg' || fileType === 'jpeg') {
          const url = URL.createObjectURL(evt.target.files[0]);
          Jimp.read(url).then((image) => {
            const height = image.getHeight();
            const width = image.getWidth();

            let rate = 1;

            if (height > 1000 || width > 1000) {
              const _rate = 1000 / Math.ceil(height > width ? height : width);
              rate = Math.ceil(_rate * Math.pow(10, 3)) / Math.pow(10, 3);
            }

            image.scale(rate).getBase64(Jimp.MIME_JPEG, async (err, src) => {
              // s3更新用URL取得
              const updateUrl = data?.userPhotoPutUrl;

              if (updateUrl) {
                // s3 put
                await photoPut(updateUrl, src.replace(JpegBase64Prefix, ''));

                if (refetch) refetch();
              }
            });
          });
        } else {
          confirm({ title: ERROR_TITLE_BASE, message: IMAGE_FILE_EXTENSION, type: 'alert' });
        }
      } catch (e: any) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <div className="flex w-full h-[10.25rem] m-0 items-center">
        <div className="w-28 h-28 items-center relative">
          {imageUrl ? (
            <ImageView url={imageUrl} className="w-25 h-25 rounded-full" isUserIcon />
          ) : (
            <UserIcon className="w-25 h-25" />
          )}
          <img
            src="/assets/img/icon_camera.png"
            alt=""
            className="w-11 h-11 absolute bottom-0 right-0"
          />
          <label className="opacity-0 top-0 bottom-0 left-0 right-0 absolute cursor-pointer">
            <input type="file" className="hidden" accept="image/jpeg" onChange={imageChange} />
          </label>
        </div>
        <div>
          <div className="ml-8">
            <div>
              {labels?.map((label) => {
                return <img key="" src={`/assets/img/${label}.png`} alt="" className="mr-4" />;
              })}
            </div>
            <p>
              <span className="font-bold mr-4 text-xl">{nickName}</span>
              さん
            </p>
            {userId && (
              <p className="mt-2">
                会員ID<span className="ml-4">{userId}</span>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
