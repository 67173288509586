import MockAdapter from 'axios-mock-adapter';

export const registerMock = (mock: MockAdapter) => {
  mock.onPost('/evc_detail_base_ref_api').reply(200, {
    result: 0,
    err: [
      {
        errCode: 0,
        errMsg: '',
      },
    ],
    data: {
      reservationFlag: 2,
      autoUpdateFlag: false,
      supportFlag: false,
      supportMessage: 'メッセージあり',
      autoApprovalFlag: false,
      userKind: 2,
    },
  });
};
